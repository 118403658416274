import { useState } from 'react';

import { Submission } from '@conform-to/react';
import { v4 } from 'uuid';

import {
  DeleteOutlined,
  DriveFileRenameOutlineOutlined,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button as ButtonMUI,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';

import { fetchDeleteAdmissionDraftAttachment } from '@octopus/api';
import { Parentesco } from '@octopus/esocial/mapper';
import { Button } from '@octopus/ui/design-system';

import { Label } from '../../../../modules/form/Field/commons/Label';
import { AdmissionFormState, FormStepDefinition } from '../form/types';

import { Dependent, extractParentescoType } from './formStepInputs';
import { NewAdmissionDependentForm } from './NewAdmissionDependentForm';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  formState: AdmissionFormState;
  stepDefinition: FormStepDefinition;
  onSubmitCallback: (newFormState: AdmissionFormState) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  organizationId: string;
  companyId: string;
  draftId: string;
};

const emptyDependent: Dependent = {
  nome: '',
  parentesco: {
    type: '',
    descrDep: '',
  },
  cpf: '',
  sexo: '',
  dtNascto: '',
  incTrab: 'false',
  calculos: [],
  file: null,
};

export const AddNewDependent = ({
  disabled,
  isLoading,
  formState,
  stepDefinition,
  onSubmitCallback,
  goToNextStep,
  goToPreviousStep,
  organizationId,
  companyId,
  draftId,
}: Props) => {
  const onDependenteSubmit = (
    _event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => {
    const currentDependent = formData.payload as Dependent;

    const depList = dependentes || [];

    if (currentDependentIndex === -1) {
      depList.push({ ...currentDependent });
    } else {
      depList[currentDependentIndex] = { ...currentDependent };
    }

    const newFormState = {
      ...formState,
      dependentes: [...depList],
    };

    setDependentes(depList);
    onSubmitCallback(newFormState);
    setCurrentDependentIndex(-1);
    setIsEditing(false);
    setTempDependentId(v4());
  };

  const editDependente = (dependentIdx: number) => {
    setIsEditing(true);
    setCurrentDependentIndex(dependentIdx);
  };

  const toggleCurrentDependent = (dependentIdx: number) => {
    setIsEditing(!isEditing);
    setCurrentDependentIndex(currentDependentIndex === -1 ? dependentIdx : -1);
  };

  const deleteDependent = (dependentIdx: number) => {
    const removed = dependentes.splice(dependentIdx, 1);

    const fileId = removed?.[0]?.file?.id;

    if (fileId) {
      fetchDeleteAdmissionDraftAttachment({
        pathParams: {
          draftId,
          organizationId,
          attachmentId: fileId,
        },
      });
    }

    const newFormState = {
      ...formState,
      dependentes: [...dependentes],
    };

    setDependentes(dependentes);
    onSubmitCallback(newFormState);
    setCurrentDependentIndex(-1);
    setHasDependentes(false);
    setIsEditing(false);
  };

  const [dependentes, setDependentes] = useState<Dependent[]>(
    formState?.dependentes,
  );
  const [tempDependentId, setTempDependentId] = useState<string>(v4());

  const [currentDependentIndex, setCurrentDependentIndex] =
    useState<number>(-1);

  const [isEditing, setIsEditing] = useState<boolean>();
  const [hasDependentes, setHasDependentes] = useState<boolean>();

  if (!formState || !stepDefinition) {
    return null;
  }
  const dependentesCount = dependentes?.length;

  const defaultValue =
    hasDependentes == null
      ? { defaultValue: dependentesCount > 0 ? 'true' : 'false' }
      : { defaultValue: hasDependentes.toString() };

  const renderSelectedValue = (selected: string) => {
    if (selected == null) {
      return 'Selecione uma opção';
    }

    return selected === 'true' ? 'Sim' : 'Não';
  };

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          height: `calc(100svh - ${theme.spacing(38)})`,
          overflow: 'scroll',
          '::-webkit-scrollbar': { display: 'none' },
        },
      })}
    >
      {(!dependentes || dependentesCount <= 0) && (
        <Box>
          <Label
            field={{
              uiType: 'SELECT',
              fieldsetName: '',
              props: {
                key: 'hasDependents',
                id: 'hasDependents',
                name: 'hasDependents',
                form: '',
              },
              select: {
                props: {
                  required: true,
                  key: 'hasDependents',
                  id: 'hasDependents',
                  name: 'hasDependents',
                  form: '',
                },
                options: [],
              },
              label: {
                textContent: 'Você possui dependentes?',
                props: { htmlFor: '' },
              },
            }}
          />
          <Select
            fullWidth
            displayEmpty
            {...defaultValue}
            value={hasDependentes?.toString()}
            onChange={(e) => setHasDependentes(e.target.value === 'true')}
            renderValue={renderSelectedValue}
          >
            <MenuItem key={'sim'} value={'true'}>
              Sim
            </MenuItem>
            <MenuItem key={'nao'} value={'false'}>
              Não
            </MenuItem>
          </Select>
        </Box>
      )}
      {dependentesCount > 0 && (
        <Box display="flex" flexDirection="column" gap={2}>
          {Object.entries(dependentes).map(([key, dep], index) => (
            <Box
              key={key}
              gap={1}
              data-testid="dependente-card"
              sx={(theme) => ({
                border: '1px solid',
                borderRadius: '8px',
                borderColor: theme.palette.strokes.light,
                cursor: 'pointer',
                [theme.breakpoints.up('md')]: {
                  padding: 3,
                },
                [theme.breakpoints.down('md')]: {
                  padding: 2,
                },
              })}
              onClick={() => toggleCurrentDependent(index)}
            >
              <Box display="flex" flexDirection="row">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={'space-between'}
                >
                  <Typography variant="h4">{`${index + 1}. ${dep.nome}`}</Typography>
                  <Typography
                    variant="caption"
                    mt={0.5}
                    mb={0.5}
                    flexWrap={'wrap'}
                  >
                    {Parentesco.getByCode(extractParentescoType(dep))}
                  </Typography>
                </Box>
                {currentDependentIndex !== index && (
                  <Button
                    disabled={isLoading || disabled}
                    sx={{
                      marginLeft: 'auto',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                    variantSemantic="highlight"
                    variantLayout="tiny"
                    onClick={() => editDependente(index)}
                  >
                    <DriveFileRenameOutlineOutlined fontSize="medium" />
                  </Button>
                )}
                {isEditing && currentDependentIndex === index && (
                  <Button
                    disabled={isLoading || disabled}
                    sx={{
                      marginLeft: 'auto',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                    variantSemantic="secondary"
                    variantLayout="tiny"
                    onClick={() => deleteDependent(index)}
                  >
                    <DeleteOutlined fontSize="medium" color="error" />
                  </Button>
                )}
              </Box>
              {isEditing && currentDependentIndex === index && (
                <Box sx={{ mt: 2 }} onClick={(e) => e.stopPropagation()}>
                  <NewAdmissionDependentForm
                    disabled={disabled}
                    isLoading={isLoading}
                    formState={formState}
                    stepDefinition={stepDefinition}
                    currentDependente={dep}
                    onSubmitCallback={onDependenteSubmit}
                    submitLabel={'Atualizar dependente'}
                    organizationId={organizationId}
                    companyId={companyId}
                    draftId={draftId}
                  />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}

      {(hasDependentes === true || dependentesCount > 0) &&
        !isEditing &&
        currentDependentIndex === -1 && (
          <Box sx={{ mt: 2 }}>
            <NewAdmissionDependentForm
              disabled={disabled}
              isLoading={isLoading}
              formState={formState}
              stepDefinition={stepDefinition}
              currentDependente={{ ...emptyDependent, id: tempDependentId }}
              onSubmitCallback={onDependenteSubmit}
              submitLabel={'+ Adicionar Dependente'}
              organizationId={organizationId}
              companyId={companyId}
              draftId={draftId}
            />
          </Box>
        )}
      <Box
        sx={(theme) => ({
          width: '100%',
          [theme.breakpoints.up('md')]: { display: 'flex', paddingTop: 4 },
          [theme.breakpoints.down('md')]: {
            position: 'fixed',
            bottom: 0,
            paddingBottom: 3,
            left: 0,
            background: theme.palette.background.paper,
            borderColor: 'transparent',
            border: 'none',
            px: 4,
          },
        })}
      >
        <LoadingButton
          fullWidth
          color="primaryAlt"
          variant="contained"
          disabled={disabled}
          loading={isLoading}
          sx={(theme) => ({
            py: 1.25,
            [theme.breakpoints.up('md')]: {
              px: 6,
              marginLeft: 'auto',
              width: 'auto',
            },
            [theme.breakpoints.down('md')]: {
              px: 2,
              display: 'block',
              width: 'calc(100svw - 64px)',
            },
          })}
          onClick={goToNextStep}
          data-testid={`submit-dependentes`}
        >
          Avançar
        </LoadingButton>
        <ButtonMUI
          fullWidth
          color="secondary"
          variant="contained"
          disabled={disabled}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
            [theme.breakpoints.down('md')]: {
              py: 1.25,
              px: 2,
              mt: 1.5,
              display: 'block',
              width: 'calc(100svw - 64px)',
            },
          })}
          onClick={goToPreviousStep}
        >
          Voltar
        </ButtonMUI>
      </Box>
    </Box>
  );
};
