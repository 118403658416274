import { useContext, useEffect, useMemo } from 'react';

import { LDContext, initialize } from 'launchdarkly-js-client-sdk';

import { createFFlags } from '@octopus/ui/flags';

import { IAppContext } from '../modules/types';

import { AppContext } from './context';

export const features = {
  canAccessAdmissionPage: 'canAccessAdmissionPage',
  canAccessVacationInputs: 'canAccessVacationInputs',
  canAccessEmployeeEdit: 'canAccessEmployeeEdit',
  canAccessContractorEdit: 'canAccessContractorEdit',
  canAccessVacationScheduler: 'canAccessVacationScheduler',
  isPjEnabledForOrganization: 'isPjEnabledForOrganization',
  isCltEnabledForOrganization: 'isCltEnabledForOrganization',
  isCompanyTransferEnabledForOrganization:
    'isCompanyTransferEnabledForOrganization',
  isProbationPeriodVisible: 'isProbationPeriodVisible',
} as const;

const FEATURE_TO_FLAG_MAPPING: {
  [key in keyof typeof features]: {
    key: string;
    defaultValue: boolean;
  };
} = {
  canAccessAdmissionPage: {
    key: 'has-access-to-admission-page',
    defaultValue: false,
  },
  canAccessVacationInputs: {
    key: 'has-access-to-vacation-inputs',
    defaultValue: false,
  },
  canAccessVacationScheduler: {
    key: 'hasAccessToVacationsScheduler',
    defaultValue: false,
  },
  canAccessEmployeeEdit: {
    key: 'has-access-to-employee-edit',
    defaultValue: false,
  },
  canAccessContractorEdit: {
    key: 'has-access-to-contractor-edit',
    defaultValue: false,
  },
  isPjEnabledForOrganization: {
    key: 'is-pj-enabled-for-organization',
    defaultValue: false,
  },
  isCltEnabledForOrganization: {
    key: 'is-clt-enabled-for-organization',
    defaultValue: true,
  },
  isCompanyTransferEnabledForOrganization: {
    key: 'is-company-transfer-enabled-for-organization',
    defaultValue: false,
  },
  isProbationPeriodVisible: {
    key: 'is-probation-period-visible',
    defaultValue: false,
  },
} as const;

const flagNames = Object.values(features);

export const { FFlagsProvider, useFFlags, resolveFFlags } =
  createFFlags(flagNames);

export function useResolveFFlags() {
  const { isError, error, appContext } = useContext(AppContext);
  const ldClient = useMemo(
    () =>
      initialize('6696d0363fdedb0fa94429a8', {
        kind: 'init',
        key: 'auditing',
        anonymous: true,
      }),
    [],
  );

  useEffect(() => {
    resolveFFlags(async (fflags) => {
      if (isError) {
        console.error(
          `Error fetching app context. Couldn't initialize fflags.`,
        );
        console.error(error);
        return;
      }
      if (
        appContext?.membership?.organizationId &&
        appContext?.user?.userId &&
        appContext?.user?.email
      ) {
        await ldClient.identify(prepareContext(appContext));
        for (const [feature, { key, defaultValue }] of Object.entries(
          FEATURE_TO_FLAG_MAPPING,
        )) {
          fflags.set(
            feature as keyof typeof features,
            ldClient.variation(key, defaultValue),
          );
        }
      }
    });
  }, [
    appContext?.membership?.organizationId,
    appContext?.user?.userId,
    appContext?.user?.email,
  ]);
}

function prepareContext(appContext: IAppContext): LDContext {
  return {
    kind: 'multi',
    user: {
      key: appContext.user.userId,
      email: appContext.user.email,
    },
    organization: {
      key: appContext.membership.organizationId,
    },
  };
}
