const PayrollErrorCodes: Record<string, string> = {
  'RE002/PC001': 'Estagiario may create only monthly or termination payrolls',
  'RE002/PC002':
    'DiretorSemFgts and DiretorComFgts are allowed to create only monthly and termination payrolls',
  'RE002/PC003': 'Thirteenth payroll validations',
  'RE002/PC004':
    'Termination may not be created for employee with planned vacations',
  'RE002/PC005': 'Termination may not be created during employee vacations',
};

export default PayrollErrorCodes;
