import dayjs from 'dayjs';

import {
  MembershipTypes,
  PayrollTerminationEntry,
  PayrollTypes,
} from '@octopus/api';
import {
  formatCNPJ,
  formatCPF,
  formatPhoneBR,
  formatUUID,
} from '@octopus/formatters';

import { PixKeyType, pixKeyTypes } from './payrolls/parser';

export const formatOptionalDayjsDate = ({
  date,
  format,
}: {
  date: dayjs.Dayjs | undefined | null;
  format?: string;
}) => {
  return date ? date.format(format ?? 'DD/MM/YYYY') : '--';
};

export function formatPayrollType(type: PayrollTypes, long = false) {
  const labels: { [key in PayrollTypes]: { short: string; long: string } } = {
    monthly: {
      short: 'Mensal',
      long: 'Folha mensal',
    },
    advance: {
      short: 'Adiantamento',
      long: 'Folha de adiantamento',
    },
    complementary: {
      short: 'Complementar',
      long: 'Folha complementar',
    },
    complementaryTermination: {
      short: 'Rescisão Complementar',
      long: 'Folha de rescisão complementar',
    },
    termination: {
      short: 'Rescisão',
      long: 'Folha de rescisão',
    },
    vacations: {
      short: 'Férias',
      long: 'Folha de férias',
    },
    thirteenthFirst: {
      short: '13º salário - Adiantamento',
      long: 'Folha de 13º salário - Adiantamento',
    },
    thirteenthSecond: {
      short: '13º salário - 2ª parcela',
      long: 'Folha de 13º salário - 2ª parcela',
    },
    provisions: {
      short: 'Provisões',
      long: 'Folha de Provisões',
    },
  };
  const label = labels[type];
  return long ? label.long : label.short;
}

export function formatPayslipType(type: PayrollTypes) {
  switch (type) {
    case 'monthly':
      return 'Holerite mensal';
    case 'advance':
      return 'Holerite de adiantamento';
    case 'complementary':
      return 'Holerite complementar';
    case 'complementaryTermination':
      return 'Holerite de rescisão complementar';
    case 'termination':
      return 'Holerite de rescisão';
    case 'vacations':
      return 'Holerite de férias';
    case 'thirteenthFirst':
      return 'Holerite de 13º salário - Adiantamento';
    case 'thirteenthSecond':
      return 'Holerite de 13º salário - 2ª parcela';
    case 'provisions':
      return 'Provisões';
  }
}

export function formatTerminationType(
  noticeIndemnityType?: PayrollTerminationEntry['noticeIndemnityType'],
): string {
  const LABELS = {
    PAID: 'Indenizado',
    DEDUCTED: 'Descontado',
    WORKED: 'Trabalhado',
    EXEMPT: 'Dispensado',
    OTHER: 'Outro',
  };

  if (!noticeIndemnityType) {
    return '––';
  }

  const labelByType: Record<typeof noticeIndemnityType, string> = {
    indemnified: LABELS.PAID,
    deducted: LABELS.DEDUCTED,
    exempt: LABELS.EXEMPT,
    worked: LABELS.WORKED,
    other: LABELS.OTHER,
  };

  return labelByType[noticeIndemnityType];
}

export function formatTerminationReason(type: string): string {
  const terminationTypeString: Record<string, string> = {
    resignation: 'Pedido de demissão',
    pedidoDeDemissao: 'Pedido de demissão',
    justified: 'Com justa causa',
    comJustaCausa: 'Com justa causa',
    unjustified: 'Sem justa causa',
    semJustaCausa: 'Sem justa causa',
    mutualAgreement: 'Mútuo acordo',
    mutuoAcordo: 'Mútuo acordo',
    death: 'Falecimento',
    falecimento: 'Falecimento',
    endOfExpPeriod: 'Extinção de contrato de experiência',
    extincaoDeContratoDeExperiencia: 'Extinção de contrato de experiência',
    earlyTerminationOfExpPeriodByEmployer:
      'Rescisão Antecipada do Contrato de Experiência pelo Empregador',
    rescisaoAntecipadaDoContratoDeExperienciaPeloEmpregador:
      'Rescisão Antecipada do Contrato de Experiência pelo Empregador',
    earlyTerminationOfExpPeriodByEmployee:
      'Rescisão Antecipada do Contrato de Experiência pelo Empregado',
    rescisaoAntecipadaDoContratoDeExperienciaPeloEmpregado:
      'Rescisão Antecipada do Contrato de Experiência pelo Empregado',
  };
  return terminationTypeString[type] ?? type;
}

export function formatTerminationStartedBy(
  terminationStartedBy?: PayrollTerminationEntry['startedBy'],
): string {
  const LABELS = {
    EMPLOYER: 'Empresa',
    EMPLOYEE: 'Colaborador',
    BOTH: 'Ambos',
    OTHER: 'Outro',
  };

  if (!terminationStartedBy) {
    return '––';
  }

  const labelByEntity: Record<typeof terminationStartedBy, string> = {
    employer: LABELS.EMPLOYER,
    employee: LABELS.EMPLOYEE,
    both: LABELS.BOTH,
    other: LABELS.OTHER,
  };

  return labelByEntity[terminationStartedBy] || '--';
}

export function formatMembershipType(
  membershipType: MembershipTypes,
  active: boolean,
): string {
  if (active) {
    if (membershipType === 'owner') {
      return 'Administrador(a)';
    }
    if (membershipType === 'tako:support') {
      return 'Tako Suporte';
    }
    if (membershipType === 'internal') {
      return 'Colaborador(a)';
    }
  } else {
    if (membershipType === 'internal') {
      return 'Ex-Colaborador(a)';
    }
  }
  return 'Convidado(a)';
}

export function formatPixKey(
  value: string | undefined,
  type: PixKeyType | undefined,
) {
  if (!value) {
    return '';
  }
  if (!type) {
    return value;
  }
  switch (type) {
    case pixKeyTypes.cpf:
      return formatCPF(value);
    case pixKeyTypes.cnpj:
      return formatCNPJ(value);
    case pixKeyTypes.celular:
      return formatPhoneBR(value);
    case pixKeyTypes.aleatoria:
      return formatUUID(value);
    default:
      return value;
  }
}
