import { AuditableVersionedEntry } from '@octopus/api';

export type VacationsScheduleKeyParameters = {
  organizationId: string;
  contractId: string;
  sequence: number;
};

export type VacationsScheduleKeys = {
  partitionKey: {
    organizationId: string;
    contractId: string;
  };

  sortKey: {
    sequence: number;
  };
};

export type VacationsSchedule = AuditableVersionedEntry & {
  // organizationId
  partitionKey: string;

  // contractId/startDate/sequence
  sortKey: string;

  // for GSI SK
  accrualPeriodStart: string;

  organizationId: string;
  contractId: string;
  companyId: string;
  legalEntityId: string;

  sequence: number;

  startDate: string;
  endDate: string;
  daysTaken: number;
  daysSold: number;
  thirteenthAdvance: boolean;

  accrualPeriod: {
    startDate: string;
    endDate: string;
  };

  status: VacationsScheduleStatus;

  workerData: WorkerData;

  approvalDeadline?: string;

  approvalWorkflowId?: string;

  /**
   * the id of the payroll that was created by this schedule
   */
  payrollId?: string;

  paymentDate: string;
};

export type WorkerData = {
  name: string;
  department: string;
  location: {
    city: string;
    state: string;
  };
};

export type VacationsScheduleCreatePayload = Omit<
  VacationsSchedule,
  keyof VacationsScheduleKeys | keyof AuditableVersionedEntry | 'review'
>;

export type VacationsScheduleUpdatePayload =
  Partial<VacationsScheduleCreatePayload> & {
    payrollId?: string;
  };

export const vacationsScheduleStatuses = {
  created: 'created',
  waitingApproval: 'waitingApproval',
  approved: 'approved',
  rejected: 'rejected',
  canceled: 'canceled',
  payrollCreated: 'payrollCreated',
  payrollApproved: 'payrollApproved',
  contractTerminated: 'contractTerminated',
} as const;

export const vacationsScheduleCommands = {
  createApprovalWorkflow: 'createApprovalWorkflow',
  approve: 'approve',
  reject: 'reject',
  cancel: 'cancel',
  createPayroll: 'createPayroll',
  approvePayroll: 'approvePayroll',
  removePayrollApproval: 'removePayrollApproval',
  notifyContracts: 'notifyContracts',
  delete: 'delete',
  create: 'create',
  addReview: 'addReview',
  terminate: 'terminate',
} as const;

export type VacationsScheduleStatus = keyof typeof vacationsScheduleStatuses;
export type VacationsScheduleCommand = keyof typeof vacationsScheduleCommands;
