import { ReactElement, useEffect, useState } from 'react';

import { v4 } from 'uuid';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  ContractBRCltUpdate,
  ContractBRPjUpdate,
  ContractEntry,
  ContractUpdate,
  ErrorsBadRequest,
  ErrorsIssue,
  useCompanyTransferContract,
  useInternalTransferContract,
  usePutContract,
} from '@octopus/api';

import { RecordEditProps } from '../../../modules/components/people/common';
import { useSnackbar } from '../../../modules/hooks/useSnackbar';
import { QueryResult } from '../../../modules/types';
import { getDiffs } from '../../../utils/getDiffs';

import { ContractEditError } from './types';
import { UpdateDialog as UpdateDialogComponent } from './UpdateDialog';

type ContractUpdateData = ContractBRCltUpdate | ContractBRPjUpdate;

export function useContractEdit({
  contractQuery,
  isEditable,
  afterUpdate = () => {
    /**/
  },
}: {
  contractQuery: QueryResult<ContractEntry>;
  isEditable: boolean;
  afterUpdate?: () => void;
}):
  | {
      updateRecordProps: RecordEditProps<ContractUpdateData>;
      internalTransferRecordProps: RecordEditProps<{ legalEntityId: string }>;
      companyTransferRecordProps: RecordEditProps<{
        companyId: string;
        legalEntityId: string;
        workerId: string;
        matricula: string;
      }>;
      UpdateDialog: ReactElement;
    }
  | undefined {
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const { showSnackbar } = useSnackbar();

  const [saveOperation, setSaveOperation] = useState<{
    operation: 'none' | 'update' | 'internalTransfer' | 'companyTransfer';
    isLoading: boolean;
    isError: boolean;
    error?: ContractEditError;
    mutate: (effectiveDate: string) => void;
    onSuccess: () => void;
    onError?: (err: ContractEditError) => void;
  }>({
    operation: 'none',
    isLoading: false,
    isError: false,
    mutate: undefined,
    onSuccess: undefined,
    onError: undefined,
  });

  const putContractCall = usePutContract();
  useEffect(() => {
    if (saveOperation.operation === 'update') {
      setSaveOperation((state) => ({
        ...state,
        isLoading: putContractCall.isLoading,
        isError: putContractCall.isError,
        error: parseUpdateError(putContractCall.error),
      }));
    }
  }, [putContractCall.isLoading, putContractCall.isError]);

  const internalTransferCall = useInternalTransferContract();
  useEffect(() => {
    if (saveOperation.operation === 'internalTransfer') {
      setSaveOperation((state) => ({
        ...state,
        isLoading: internalTransferCall.isLoading,
        isError: internalTransferCall.isError,
        error: parseInternalTransferError(internalTransferCall.error),
      }));
    }
  }, [internalTransferCall.isLoading, internalTransferCall.isError]);

  const companyTransferCall = useCompanyTransferContract();
  useEffect(() => {
    if (saveOperation.operation === 'companyTransfer') {
      setSaveOperation((state) => ({
        ...state,
        isLoading: companyTransferCall.isLoading,
        isError: companyTransferCall.isError,
        error: parseInternalTransferError(companyTransferCall.error),
      }));
    }
  }, [companyTransferCall.isLoading, companyTransferCall.isError]);

  const { isLoading, isError, data: contract, refetch } = contractQuery;

  if (isLoading || isError || !contract) {
    return undefined;
  }

  const {
    organizationId,
    companyId,
    contractId,
    contractType,
    legalEntityId,
    workerId,
    br,
  } = contract;

  const onUpdate: RecordEditProps<ContractUpdateData>['onSave'] = (
    { update: contractUpdate, workerId: newWorkerId },
    onSuccess,
    onError,
  ) => {
    const dataToUpdate = getDiffs<ContractUpdateData>(br, contractUpdate, {
      setEmptyToNull: true,
    });
    const workerIdUpdate =
      !!newWorkerId && workerId !== newWorkerId
        ? { workerId: newWorkerId }
        : {};
    if (
      Object.keys(dataToUpdate).length + Object.keys(workerIdUpdate).length >
      0
    ) {
      if (!!dataToUpdate.endereco && !dataToUpdate.endereco.tipo) {
        dataToUpdate.endereco.tipo = contractUpdate.endereco.tipo;
      }

      const ctlDataToUpdate = dataToUpdate as ContractBRCltUpdate;
      ctlDataToUpdate.dependentes?.forEach((dep) => {
        if (dep.tpDep !== 99) {
          dep.descrDep = '';
        }
      });

      const idempotencyKey = v4();
      setSaveOperation({
        operation: 'update',
        isLoading: putContractCall.isLoading,
        isError: putContractCall.isError,
        mutate: (effectiveDate) =>
          putContractCall.mutate({
            pathParams: {
              organizationId,
              contractId,
            },
            body: {
              effectiveDate,
              contractType,
              ...workerIdUpdate,
              br: dataToUpdate,
            } as ContractUpdate,
            headers: {
              'x-idempotency-key': idempotencyKey,
            },
          }),
        onSuccess,
        onError,
      });
      setShowUpdateDialog(true);
    }
  };

  const onInternalTransfer: RecordEditProps<{
    legalEntityId: string;
  }>['onSave'] = ({ update: destination }, onSuccess, onError) => {
    if (legalEntityId !== destination.legalEntityId) {
      const idempotencyKey = v4();
      setSaveOperation({
        operation: 'internalTransfer',
        isLoading: internalTransferCall.isLoading,
        isError: internalTransferCall.isError,
        mutate: (effectiveDate) =>
          internalTransferCall.mutate({
            pathParams: {
              organizationId,
              contractId,
            },
            body: {
              contractType,
              effectiveDate,
              destination: {
                legalEntityId: destination.legalEntityId,
              },
            },
            headers: {
              'x-idempotency-key': idempotencyKey,
            },
          }),
        onSuccess,
        onError,
      });
      setShowUpdateDialog(true);
    }
  };

  const onCompanyTransfer: RecordEditProps<{
    companyId: string;
    legalEntityId: string;
    workerId: string;
    matricula: string;
  }>['onSave'] = ({ update: destination }, onSuccess, onError) => {
    if (companyId !== destination.companyId) {
      const idempotencyKey = v4();
      setSaveOperation({
        operation: 'companyTransfer',
        isLoading: companyTransferCall.isLoading,
        isError: companyTransferCall.isError,
        mutate: (effectiveDate) =>
          companyTransferCall.mutate({
            pathParams: {
              organizationId,
              contractId,
            },
            body: {
              newCompanyId: destination.companyId,
              newLegalEntityId: destination.legalEntityId,
              newWorkerId: destination.workerId,
              br: {
                novaMatricula: destination.matricula,
              },
              effectiveDate,
            },
            headers: {
              'x-idempotency-key': idempotencyKey,
            },
          }),
        onSuccess,
        onError,
      });
      setShowUpdateDialog(true);
    }
  };

  const updateRecordProps = {
    isEditable,
    onSave: onUpdate,
  };

  const internalTransferRecordProps = {
    isEditable,
    onSave: onInternalTransfer,
  };

  const companyTransferRecordProps = {
    isEditable,
    onSave: onCompanyTransfer,
  };

  const UpdateDialog = (
    <UpdateDialogComponent
      contractType={contractType}
      startDate={
        contractType === 'br:clt'
          ? contract.br.regime.dtAdm
          : contract.br.contrato.inicio
      }
      open={showUpdateDialog}
      onClose={() => setShowUpdateDialog(false)}
      onSuccess={() => {
        refetch();
        afterUpdate();
        setTimeout(() => {
          saveOperation.onSuccess();
          setShowUpdateDialog(false);
          showSnackbar({
            isOpen: true,
            variant: 'default',
            Message: 'Sua alteração foi enviada com sucesso.',
            StartAdornment: <CheckCircleIcon />,
            hasCloseAction: true,
          });
        }, 200);
      }}
      onError={() => {
        if (saveOperation.onError) {
          saveOperation.onError(saveOperation.error);
        }
        setShowUpdateDialog(false);
        showSnackbar({
          isOpen: true,
          variant: 'error',
          Message:
            saveOperation?.error?.message ||
            'Ocorreu um erro ao enviar sua alteração. Tente novamente.',
          StartAdornment: <CancelIcon />,
          hasCloseAction: true,
        });
      }}
      isLoading={saveOperation.isLoading}
      isError={saveOperation.isError}
      mutate={saveOperation.mutate}
    />
  );

  return {
    updateRecordProps,
    internalTransferRecordProps,
    companyTransferRecordProps,
    UpdateDialog,
  };
}

function parseUpdateError(err: unknown): ContractEditError {
  try {
    if (err instanceof Object) {
      if ('statusCode' in err) {
        if (err.statusCode === 400) {
          const message =
            'Alteração inválida. Verifique os campos e tente novamente.';
          if ('stack' in err && err.stack instanceof Object) {
            const requestError = err.stack as ErrorsBadRequest;
            const bodyIssues = requestError?.errors?.filter(
              (err) => err.component === 'body',
            )?.[0]?.issues?.[0];
            if (bodyIssues) {
              if (bodyIssues.subIssues?.length > 0) {
                const validationIssues = bodyIssues?.subIssues?.filter(
                  (subIssues) => {
                    if (Array.isArray(subIssues)) {
                      return subIssues.every(
                        (subIssue) => subIssue?.path?.[0] !== 'contractType',
                      );
                    }
                    return false;
                  },
                )[0] as ErrorsIssue[] | undefined;
                return {
                  message,
                  fields: validationIssues?.reduce(
                    (fields, subIssue) => ({
                      ...fields,
                      [subIssue.path.join('/')]: subIssue.message,
                    }),
                    {},
                  ),
                };
              } else {
                return {
                  message,
                  fields: {
                    [bodyIssues.path.join('/')]: bodyIssues.message,
                  },
                };
              }
            }
          }
          return {
            message,
          };
        }
        if (err.statusCode === 412) {
          return {
            message:
              'Alteração não pode ser efetivada. Entre em contato com o suporte da Tako.',
          };
        }
      }
    }
  } catch (err) {
    console.warn('Failed to parse error', err);
  }
  return {
    message: 'Ocorreu um erro ao enviar sua alteração. Tente novamente.',
  };
}

function parseInternalTransferError(err: unknown): ContractEditError {
  if (err instanceof Object) {
    if ('statusCode' in err) {
      if (err.statusCode === 412) {
        return {
          message:
            'Alteração não pode ser efetivada. Entre em contato com o suporte da Tako.',
        };
      }
    }
  }
  return {
    message: 'Ocorreu um erro ao enviar sua alteração. Tente novamente.',
  };
}
