import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Box, useTheme } from '@mui/material';

import {
  VacationsAccrualPeriodEntry,
  VacationsConfigurationEntry,
  fetchGetContractAccrualPeriods,
  fetchGetVacationsConfigurationByContract,
} from '@octopus/api';
import { createScheduleRules } from '@octopus/vacations-types';

import RequestVacationContainer from './RequestVacationContainer';
import { LoadingScene } from './utils/RequestVacationLoadingScene';
import {
  RequestVacationFormSteps,
  RequestVacationFormStepsKeys,
  requestVacationObject,
} from './utils/types';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

function VacationRequestPage({
  organizationId,
}: {
  organizationId: string | undefined;
}) {
  const [requestVacation, setRequestVacation] = useState<requestVacationObject>(
    {},
  );
  const theme = useTheme();
  const { contractId } = useParams();
  const [searchParams] = useSearchParams();

  const [accrualPeriod, setAccrualPeriod] = useState<
    VacationsAccrualPeriodEntry & { nextSequence: number }
  >(null);

  const [vacationsConfiguration, setVacationsConfiguration] =
    useState<VacationsConfigurationEntry>(null);

  const startDate = searchParams.get('startDate');

  const accrualPeriodsQuery = useQuery({
    queryKey: [organizationId, contractId],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => {
      return fetchGetContractAccrualPeriods({
        pathParams: {
          organizationId: organizationId ?? '',
          contractId: contractId ?? '',
        },
        queryParams: {
          effectiveDate: dayjs().add(366, 'day').format('YYYY-MM-DD'),
        },
      });
    },
    enabled: !!organizationId,
  });
  const formSteps: RequestVacationFormSteps = useMemo(() => {
    const filteredOutSteps: RequestVacationFormStepsKeys[] = [];
    const steps: RequestVacationFormStepsKeys[] = [
      'initialStep',
      'abonoPecuniario',
      'thirteenthAdvance',
      'dateAndDuration',
      'reviewAndSubmit',
      'result',
    ];
    if (accrualPeriod) {
      if (accrualPeriod.thirteenthAdvanceByYear.includes(`${dayjs().year()}`)) {
        filteredOutSteps.push('thirteenthAdvance');
      }

      if (vacationsConfiguration) {
        const minimumDaysRequiredToSell = vacationsConfiguration.rules[
          createScheduleRules.mayOnlySellAThirdOfVacations
        ].enabled
          ? 5 + Math.floor(accrualPeriod.maximumAvailableDaysForWorker / 3)
          : 6;
        if (
          !vacationsConfiguration.rules[createScheduleRules.maySellVacations]
            .enabled || // Already Sold once
          (!vacationsConfiguration.rules[
            createScheduleRules.mayOnlySellVacationsOnce
          ].enabled &&
            accrualPeriod.daysSold > 0) ||
          accrualPeriod.daysAvailable <= minimumDaysRequiredToSell
        ) {
          filteredOutSteps.push('abonoPecuniario');
        }
      }
    }
    return Object.fromEntries(
      steps
        .filter((ele) => !filteredOutSteps.includes(ele))
        .map((ele) => [ele, requestVacation]),
    );
  }, [accrualPeriod, vacationsConfiguration, requestVacation]);

  const vacationsConfigQuery = useQuery({
    queryKey: [organizationId, contractId, 'config'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => {
      return fetchGetVacationsConfigurationByContract({
        pathParams: {
          organizationId: organizationId,
          contractId: contractId,
        },
      });
    },
    enabled: !!organizationId,
  });

  useEffect(() => {
    if (accrualPeriodsQuery.data) {
      setAccrualPeriod({
        ...accrualPeriodsQuery.data.accrualPeriods.find(
          (accrualPeriod) => accrualPeriod.startDate === startDate,
        ),
        nextSequence: accrualPeriodsQuery.data.nextSequence,
      });
    }
  }, [accrualPeriodsQuery.data, startDate]);

  useEffect(() => {
    if (vacationsConfigQuery.data) {
      setVacationsConfiguration(vacationsConfigQuery.data);
    }
  }, [vacationsConfigQuery.data]);

  if (accrualPeriod === null || vacationsConfiguration === null) {
    return <LoadingScene title={`Iniciando pedido de férias`} />;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.secondary,
      }}
      height={'100%'}
    >
      {accrualPeriod ? (
        <RequestVacationContainer
          accrualPeriod={accrualPeriod}
          formSteps={formSteps}
          vacationsConfiguration={vacationsConfiguration}
          setRequestVacation={setRequestVacation}
          organizationId={organizationId}
          contractId={contractId}
        />
      ) : null}
    </Box>
  );
}

export default VacationRequestPage;
