import {
  CalendarTodayOutlined,
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  IndeterminateCheckBoxRounded,
} from '@mui/icons-material';
import { ptBR } from '@mui/material/locale';
import {
  PaletteColorOptions,
  ThemeProvider,
  alpha,
  createTheme,
} from '@mui/material/styles';

import './globals.css';

const satoshiFonts = [
  'Satoshi-Variable',
  'Satoshi-VariableItalic',
  'Satoshi-Light',
  'Satoshi-LightItalic',
  'Satoshi-Regular',
  'Satoshi-Italic',
  'Satoshi-Medium',
  'Satoshi-MediumItalic',
  'Satoshi-Bold',
  'Satoshi-BoldItalic',
  'Satoshi-Black',
  'Satoshi-BlackItalic',
];

const systemFonts = [
  // Fallback fonts:
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

declare module '@mui/material/styles' {
  interface TypeBackground {
    primary: string;
    secondary: string;
    info: string;
    success: string;
    warning: string;
    error: string;
    translucid: string;
  }

  interface TypeStrokes {
    default: string;
    heavy: string;
    light: string;
    print: string;

    primary: string;
    secondary: string;
    info: string;
    success: string;
    warning: string;
    error: string;
  }

  interface TypeShadows {
    light: string;
    medium: string;
    heavy: string;

    leftShadow: string;
    rightShadow: string;
  }

  interface PaletteColor {
    lowEmphasis?: string;
  }
  interface SimplePaletteColorOptions {
    lowEmphasis?: string;
  }

  interface Palette {
    strokes: TypeStrokes;
    shadows: TypeShadows;
    random: string[];
    primaryAlt: PaletteColor;
  }

  interface PaletteOptions {
    strokes: TypeStrokes;
    shadows: TypeShadows;
    random: string[];
    primaryAlt: PaletteColorOptions;
  }

  interface TypographyVariants {
    display1: React.CSSProperties;
    display3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display1?: React.CSSProperties;
    display3: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display1: true;
    display3: true;
  }
}

// Update the Icon's variant prop options
declare module '@mui/material/Icon' {
  interface IconPropsSizeOverrides {
    tiny: true;
    huge: true;
  }
}
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    tiny: true;
    huge: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primaryAlt: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    primaryAlt: true;
  }
}

const fontFamily = {
  fontFamily: [...satoshiFonts, ...systemFonts].join(','),
  fontWeightBold: 600,
};

const headerConfig = {
  ...fontFamily,
  letterSpacing: '-0.02em',
  lineHeight: 1.35,
  fontWeight: 600,
} as const;

let theme = createTheme({
  typography: {
    ...fontFamily,

    allVariants: {
      color: '#25252D',
    },
    // the default 1rem is 16px
    display1: {
      ...headerConfig,
      fontSize: '3rem',
    },
    display3: {
      ...headerConfig,
      fontSize: '2.5rem',
    },

    h1: {
      ...headerConfig,
      fontSize: '2rem',
    },
    h2: {
      ...headerConfig,
      fontSize: '1.5rem',
      fontWeight: 750,
    },
    h3: {
      fontSize: '1.25rem',
      ...headerConfig,
    },
    h4: {
      ...headerConfig,
      fontSize: '1rem',
    },

    subtitle1: {
      ...fontFamily,
      fontSize: '1.125rem',
      lineHeight: 1.3,
      fontWeight: 400,
      color: '#616161',
    },

    body1: {
      ...fontFamily,
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 400,
    },
    body2: {
      ...fontFamily,
      fontSize: '0.875rem',
      lineHeight: 1.35,
      fontWeight: 500,
    },

    caption: {
      ...fontFamily,
      fontSize: '0.75rem',
      lineHeight: 1.3,
      fontWeight: 450,
    },

    button: {
      ...fontFamily,
      fontSize: '0.875rem',
      lineHeight: 1.35,
      fontWeight: 400,
      textTransform: undefined,
    },
  },
  palette: {
    mode: 'light' as const,
    divider: 'rgba(0, 0, 0, 0.07)',
    text: {
      primary: '#25252D',
      secondary: '#616161',
    },
    background: {
      default: '#F7F7F9',
      paper: '#FFFFFF',
      primary: '#F0F6FF',
      secondary: '#F7F7F8',
      info: '#F0F6FF',
      success: '#F4FBF6',
      warning: '#FEF7F1',
      error: '#FDF2F4',
      translucid: 'rgba(247, 247, 249, 0.8)',
    },
    primaryAlt: {
      main: '#000000',
      contrastText: '#FFFFFF',
    },
    primary: {
      main: '#0058DB',
      light: '#1E78FF',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#616161',
    },

    strokes: {
      default: 'rgba(0, 0, 0, 0.07)',
      heavy: '#BABABF',
      light: '#EDEDED',
      print: '#25252D',

      primary: '#B0D0FF',
      secondary: '#F7F7F8',
      info: '#B0D0FF',
      success: '#BDE5CA',
      error: '#F3B1C1',
      warning: '#FADBBC',
    },
    info: {
      main: '#0058DB',
      light: '#1E78FF',
      dark: '#003480',
    },
    success: {
      main: '#066F4F',
      light: '#42B469',
      dark: '#044933',
    },
    warning: {
      main: '#93500B',
      light: '#F0993F',
      dark: '#774109',
    },
    error: {
      main: '#D9204C',
      light: '#DE1F4D',
      dark: '#701027',
      lowEmphasis: '#BC1A41',
    },

    action: {
      disabledBackground: 'rgba(0,0,0,0)',
    },

    shadows: {
      light: '0px 4px 16px 0px rgba(0, 0, 0, 0.03)',
      medium:
        '0px 2px 4px 0px rgba(0, 0, 0, 0.02), 0px 4px 16px 0px rgba(0, 0, 0, 0.03)',
      heavy:
        '0px 2px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 16px 0px rgba(0, 0, 0, 0.03)',

      leftShadow:
        '-2px 2px 4px 0px rgba(0, 0, 0, 0.02), -4px 4px 16px 0px rgba(0, 0, 0, 0.03)',
      rightShadow:
        '2px 2px 4px 0px rgba(0, 0, 0, 0.02), 4px 4px 16px 0px rgba(0, 0, 0, 0.03)',
    },

    random: [
      '#FCF9D8', // yellow
      '#FFEACD', // orange
      '#E0EFD8', // green
      '#DBEDFB', // blue
      '#D3CFE8', // purple
      '#FBD6DE', // pink
      '#FDD2C1', // red
    ],
  },
  shape: {
    borderRadius: 8,
  },
});

theme = createTheme(theme, {
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained' as const,
        disableElevation: true,
      },
      styleOverrides: {
        contained: {
          border: `1px solid ${theme.palette.strokes.default}`,
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: alpha(theme.palette.text.secondary, 0.05),
          },
        },
        containedError: {
          backgroundColor: theme.palette.error.lowEmphasis,
        },
        text: {
          fontWeight: 'bold',
        },
        iconSizeSmall: {
          '& > *:nth-of-type(1)': {
            fontSize: '0.8rem',
          },
        },
        iconSizeMedium: {
          '& > *:nth-of-type(1)': {
            fontSize: '20px',
          },
        },
        iconSizeLarge: {
          '& > *:nth-of-type(1)': {
            fontSize: '32px',
          },
        },
      },
    },

    MuiDatePicker: {
      defaultProps: {
        slots: {
          openPickerIcon: CalendarTodayOutlined,
        },
      },
    },

    MuiButtonGroup: {
      styleOverrides: {
        root: {
          border: 'none',
          borderWidth: 0,
          '& .MuiButton-root': {
            border: 'none',
          },
          '& .MuiButtonGroup-middleButton.Mui-disabled': {
            border: 'none',
          },
          '& .MuiButtonGroup-firstButton.Mui-disabled': {
            border: 'none',
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: '0px',
          },
          '& .MuiOutlinedInput-root': {
            fontSize: '14px',
            padding: '12px 16px',
          },
        },
        paper: {
          marginTop: '8px',
        },
        listbox: {
          fontSize: '14px',
          padding: '4px 0',
        },
        noOptions: {
          fontSize: '14px',
          lineHeight: 1,
          padding: '16px',
        },
        option: {
          fontSize: '14px',
          lineHeight: 1,
          '&.MuiAutocomplete-option': {
            padding: '12px 16px',
          },
        },
        loading: {
          fontSize: '14px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        adornedStart: {
          '& > :first-child': {
            marginInlineEnd: '8px',
          },
        },
        input: {
          fontSize: '14px',
          '::placeholder': {
            fontFamily: 'Satoshi-Regular',
            fontSize: '1em',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '20px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderWidth: '1px',
          fontSize: '14px',
          transition: 'all 0.2s',
          '&.Mui-disabled': {
            backgroundColor: '#F7F7F8',
            color: '#25252D',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.strokes.light}`,
          },
          '&:hover': {
            boxShadow:
              '0px 4px 16px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.04)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            transition: 'all 0.2s',
            borderColor: theme.palette.strokes.default,
          },
          '&.Mui-focused': {
            boxShadow:
              '0px 4px 16px rgba(0, 0, 0, 0.03), 0px 2px 4px rgba(0, 0, 0, 0.04)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            transition: 'all 0.2s',
            borderColor: theme.palette.strokes.default,
            border: `1px solid ${theme.palette.strokes.info}`,
          },
          '&.MuiInputBase-multiline': {
            padding: 0,
          },
        },
        notchedOutline: {
          border: '1px solid rgba(37, 37, 45, 0.10)',
          transition: 'all 0.2s',
        },
        input: {
          padding: '12px 16px',
          transition: 'all 0.2s',
          '&.Mui-disabled': {
            backgroundColor: '#F7F7F8',
            '-webkit-text-fill-color': 'unset',
          },
        },
        inputMultiline: {
          padding: '12px 16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          border: '1px solid #25252D1A',
          boxShadow: '0px 4px 12px 0px #25252D12',
        },
        root: {
          '&.MuiAccordion-root.borderless': {
            border: 'none',
            boxShadow: 'none',

            '&::before': {
              display: 'none',
            },
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        row: {
          cursor: 'pointer',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: theme.typography.caption.fontSize,
          backgroundColor: `${theme.palette.text.primary}`,
          border: '1px solid rgba(37, 37, 45, 0.10)',
        },
        tooltipArrow: {
          backgroundColor: '#F7F7F9',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: 'white',
          border: '1px solid #25252D1A',
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.common.white,
          th: {
            backgroundColor: theme.palette.background.default,
          },
          '*': {
            transition: '0.3s',
          },
          '&.MuiTableRow-hover:hover': {
            transition: ' 0.3s',
            marginRight: '50px',
            '> *': {
              transition: ' 0.3s',
              backgroundColor: theme.palette.background.default,
              '&[data-type="call-to-action"] > *': {
                paddingRight: 0,
              },
            },
          },
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: theme.palette.strokes.default,
          padding: '12px 16px',
        },

        head: {
          fontWeight: 500,
        },
      },
    },

    MuiToolbar: {
      defaultProps: {
        variant: 'dense' as const,
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 'bold',
          },
        },
      },
    },

    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
        },
      },
    },

    MuiDateCalendar: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
          height: 'auto',
          '& .MuiMonthCalendar-root': {
            paddingBottom: '16px',
          },
          '& .MuiButtonBase-root': {
            ...theme.typography.body2,
            '&:hover': {
              backgroundColor: theme.palette.strokes.default,
            },
            '&.Mui-selected': {
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.primary.main,
            },
            '&.MuiPickersDay-root:focus.Mui-selected': {
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
      },
    },

    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        icon: <CheckBoxOutlineBlankRounded />,
        checkedIcon: <CheckBoxRounded />,
        indeterminateIcon: <IndeterminateCheckBoxRounded />,
      },
      styleOverrides: {},
    },

    MuiIcon: {
      styleOverrides: {
        fontSizeTiny: {
          fontSize: '0.75rem',
        },
        fontSizeSmall: {
          fontSize: '1rem',
        },
        fontSizeMedium: {
          fontSize: '1.25rem',
        },
        fontSizeLarge: {
          fontSize: '1.5rem',
        },
        fontSizeHuge: {
          fontSize: '2rem',
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        fontSizeTiny: {
          fontSize: '0.75rem',
        },
        fontSizeSmall: {
          fontSize: '1rem',
        },
        fontSizeMedium: {
          fontSize: '1.25rem',
        },
        fontSizeLarge: {
          fontSize: '1.5rem',
        },
        fontSizeHuge: {
          fontSize: '2rem',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '40px 32px',
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
          padding: '12px',
          borderTop: `1px solid ${theme.palette.strokes.default}`,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: 8,
          '& .MuiSlider-track': {
            border: 'none',
          },
          '& .MuiSlider-mark': {
            opacity: 0,
          },
          '& .MuiSlider-thumb': {
            height: 18,
            width: 18,
            backgroundColor: '#fff',
            border: '1px solid currentColor',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
            '&::before': {
              display: 'none',
            },
          },
          '& .MuiSlider-valueLabel': {
            fontSize: 12,
            backgroundColor: theme.palette.background.primary,
            color: theme.palette.text.primary,
            borderRadius: 8,
            top: -5,
            px: 12,
            py: 4,
            border: '1px solid',
            borderColor: theme.palette.strokes.primary,
            '&::before': {
              display: 'none',
            },
          },
        },
      },
    },
  },
});

theme = createTheme(theme, ptBR);

export const defaultTheme = theme;

export function ThemeWrapper({
  children,
  theme = defaultTheme,
}: {
  children: React.ReactNode;
  theme?: ReturnType<typeof createTheme>;
}) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
