import { v4 } from 'uuid';

import {
  AdmissionDraftInputFormInfo,
  AdmissionDraftInputFormSectionEnum,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftInputFormSteps,
  AdmissionDraftUpdateInput,
} from '@octopus/api';

import { getEffectiveDate } from '../../new/form/inputUtils';
import { getUpdateInputFromFormState } from '../../new/form/mappers';
import { AdmissionFormState } from '../../new/form/types';

const getInputStepsFromFormSteps = ({
  previousFormSteps,
  section,
  currentStepName,
}: {
  previousFormSteps: AdmissionDraftInputFormSteps;
  section: AdmissionDraftInputFormSectionEnum;
  currentStepName: AdmissionDraftInputFormStepEnum;
}): AdmissionDraftInputFormSteps => {
  const data: AdmissionDraftInputFormSteps = { ...previousFormSteps };

  Object.keys(previousFormSteps).forEach((key) => {
    data[key] = {
      section,
      step: key as AdmissionDraftInputFormStepEnum,
      started: true,
      completed: true,
      reviewed:
        key === currentStepName || previousFormSteps[key]?.reviewed || false,
    };
  });
  return data;
};

const getFormInfo = ({
  previousFormInfo,
  currentStepName,
}: {
  previousFormInfo: AdmissionDraftInputFormInfo;
  currentStepName: AdmissionDraftInputFormStepEnum;
}): AdmissionDraftInputFormInfo => {
  const payroll_data = getInputStepsFromFormSteps({
    previousFormSteps: previousFormInfo?.formSections?.['payroll_data'] || {},
    section: 'payroll_data',
    currentStepName,
  });

  const personal_data = getInputStepsFromFormSteps({
    previousFormSteps: previousFormInfo?.formSections?.['personal_data'] || {},
    section: 'personal_data',
    currentStepName,
  });

  return {
    formSections: {
      payroll_data,
      personal_data,
    },
  };
};

const getUpdateBody = ({
  previousFormInfo,
  formState,
  currentStepName,
}: {
  previousFormInfo: AdmissionDraftInputFormInfo;
  formState: AdmissionFormState;
  currentStepName: AdmissionDraftInputFormStepEnum;
}): AdmissionDraftUpdateInput => {
  const formInfo = getFormInfo({
    previousFormInfo,
    currentStepName,
  });

  const updateInput = getUpdateInputFromFormState({
    formState,
    currentStepName,
  });

  return {
    contractType: formState.contractType,
    effectiveStartDate: getEffectiveDate(),
    formInfo,
    ...updateInput,
  };
};

const getHeaders = () => {
  const idempotencyKey = v4();
  return {
    'x-idempotency-key': idempotencyKey,
  };
};

export { getUpdateBody, getHeaders };
