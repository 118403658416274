const ContractErrorCodes: Record<string, string> = {
  CTR00001: 'Faltam informações pessoais',
  CTR00002: 'Data de nascimento é obrigatória',
  CTR00003:
    'Tempo de residência do and Condição de ingresso não deve ser preenchido para trabalhador imigrante',
  CTR00004:
    'Tempo de residência do and Condição de ingresso é obrigatório para trabalhador imigrante',
  CTR00005: 'Endereço é obrigatório',
  CTR00006: 'Deficiencia é obrigatório',
  CTR00007: 'CPF do dependente inválido',
  CTR00008: 'Nome da função é obrigatório para cargo de confiança',
  CTR00009: 'CBO da Função é obrigatório quando Nome da Função está preenchido',
  CTR00010: 'CBO da Função deve ser vazio se Nome da Função está vazio',
  CTR00011: 'Estágio é obrigatório para o código de categoria selecionado',
  CTR00012: 'Estágio deve ser vazio para o código de categoria selecionado',
  CTR00013:
    'Natureza da prestação de serviço civil voluntário deve ser não obrigatória',
  CTR00014:
    'Nivel do estágio é obrgiatório para o código de categoria selecionado',
  CTR00015: 'Nivel do estágio inválido para o código de categoria selecionado',
  CTR00016: 'Data Prevista de Término deve ser depois da Data de Admissão',
  CTR00017: 'CNPJ do Agente Integrador inválido',
  CTR00018: 'CPF da instituição de ensino inválido',
  CTR00019: 'CNPJ da instituição de ensino inválido',
  CTR00020:
    'Regime de Trabalho inválido para o Código de Categoria selecionado',
  CTR00021:
    'Regime Previdenciário inválido para o Código de Categoria selecionado',
  CTR00022: 'Regime Previdenciário inválido para este código de categoria',
  CTR00023: 'Regime é obrigatório para o Regime de Trabalho selecionado',
  CTR00024: 'Regime deve ser vazio para o Regime de Trabalho selecionado',
  CTR00025:
    'Número do processo trabalhista é obrigatório para admissão decorrente de ação judicial',
  CTR00026:
    'Número do processo trabalhista deve ser vazio para esse tipo de admissão',
  CTR00027:
    'Jornada em horas semanais é obrigatória para o código de categoria selecionado',
  CTR00028: 'Hora noturna é obrigatória para o código de categoria selecionado',
  CTR00029:
    'Remuneração é obrigatória para o Tipo de regime Trabalhista selecionado',
  CTR00030:
    'Remuneração deve ser vazia para o Tipo de regime Trabalhista selecionado',
  CTR00031: 'Salário deve ser 0 para a unidade selecionada',
  CTR00032: 'Descrição do salário é obrigatória para a unidade selecionada',
  CTR00033:
    'Duração é obrigatória para o Tipo de regime Trabalhista selecionado',
  CTR00034: 'Duração deve ser vazia o Tipo de regime Trabalhista selecionado',
  CTR00035:
    'Data de término deve ser vazia para o Tipo de contrato selecionado',
  CTR00036:
    'Cláusula assecuratória deve ser vazia para o Tipo de contrato selecionado',
  CTR00037: 'Data de término é obrigatória para o Tipo de contrato selecionado',
  CTR00038:
    'Objeto determinante é obrigatório para o Tipo de contrato selecionado',
  CTR00039:
    'Cláusula assecuratória é obrigatória o Tipo de contrato selecionado',
  CTR00040: 'Data de Término deve ser igual ou maior que a Data de Admissão',
  CTR00041:
    'Data da extensão do Período de experiencia só deve ser preenchido se Data de Término está preenchida',
  CTR00042:
    'Data da extensão do Período de experiencia deve ser depois da Data de Término',
  CTR00043: 'Sucessão é obrigatória para o tipo de admissão selecionado',
  CTR00044:
    'Número de inscrição deve ser CNP cálido para o Tipo de inscrição selecionado',
  CTR00045:
    'Número de inscrição deve ser CPF cálido para o Tipo de inscrição selecionado',
  CTR00046:
    'Número de inscrição deve ser CEI cálido para o Tipo de inscrição selecionado',
  CTR00047: 'Data de Transferência deve ser maior que a Data de Admissão',
  CTR00048:
    'Tipo de inscrição inválido, a menos que data de transferencia seja antes de 2011-12-31',
  CTR00049:
    'Tipo de inscrição inválido, a menos que data de transferencia seja antes de 1999-06-30',
  CTR00050: 'Contrato(br) é obrigatório',
  CTR00051: 'Tipo de contrato inválido. Esperado: CLT.',
  CTR00052: 'Campo obrigatório',
};

export default ContractErrorCodes;
