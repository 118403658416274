import { NavigateFunction, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import {
  BeachAccessOutlined,
  ChevronRight,
  ExpandMore,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Divider,
  Typography,
} from '@mui/material';

import { VacationsScheduleEntry, VacationsScheduleSummary } from '@octopus/api';
import { Tag } from '@octopus/ui/design-system';

export default function VacationsRequestedCard({
  scheduleSummary,
  detailsMode,
  historyMode,
  detailsLink,
  receiptLink,
}: {
  scheduleSummary: VacationsScheduleSummary | VacationsScheduleEntry;
  detailsMode?: boolean;
  historyMode?: boolean;
  detailsLink?: string;
  receiptLink?: string;
}) {
  const navigate = useNavigate();
  const content = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: detailsMode ? 'column' : 'row',
      }}
    >
      {renderSummary(scheduleSummary, historyMode, detailsMode)}
      {shouldRenderDetailsButton(detailsMode, historyMode) ? (
        <Button
          variant="text"
          sx={{ padding: 1, alignSelf: 'center', minWidth: 0 }}
          href={detailsLink}
        >
          <Box
            component={ChevronRight}
            sx={{ height: '24px', width: 'auto', pr: 0 }}
          />
        </Button>
      ) : null}
      {shouldRenderDetails(detailsMode, historyMode)
        ? renderDetails(scheduleSummary, receiptLink, navigate)
        : null}
    </Box>
  );

  return (
    <Card
      variant={'outlined'}
      sx={(theme) => ({
        boxSizing: 'border-box',
        py: '20px',
        px: '20px',
        borderRadius: '12px',
        mb: 1.5,
        boxShadow: theme.palette.shadows.light,
        border: 'none',
      })}
      onClick={detailsMode ? undefined : () => navigate(detailsLink)}
    >
      {content}
    </Card>
  );
}

function renderSummary(
  scheduleSummary: VacationsScheduleSummary | VacationsScheduleEntry,
  historyMode: boolean,
  detailsMode: boolean,
) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: historyMode === true ? 'row' : 'column',
        alignItems: historyMode === true ? 'center' : 'flex-start',
        justifyContent: 'space-between',
        gap: '12px',
        width: '100%',
      }}
    >
      {historyMode === true ? null : (
        <Box
          component={BeachAccessOutlined}
          sx={{ height: '32px', width: 'auto', color: '#BABABF' }}
        />
      )}
      <Box sx={{ flexGrow: 1 }}>
        <Typography fontSize={20} fontWeight={700} display={'inline'}>
          {`${scheduleSummary.daysTaken} dias`}
        </Typography>
        {scheduleSummary.daysSold > 0 && !historyMode ? (
          <Typography
            fontSize={16}
            fontWeight={500}
            display={'inline'}
            sx={(theme) => ({ color: theme.palette.strokes.heavy })}
          >
            {` | ${scheduleSummary.daysSold} dias abonados`}
          </Typography>
        ) : null}
        <Typography fontSize={14} variant="subtitle1" fontWeight={500}>
          {`De ${dayjs(scheduleSummary.startDate).format('DD/MM/YYYY')}
               a ${dayjs(scheduleSummary.endDate).format('DD/MM/YYYY')}`}
        </Typography>
      </Box>

      {shouldRenderTag(scheduleSummary, detailsMode, historyMode)
        ? getTag(
            scheduleSummary.status,
            scheduleSummary.startDate,
            scheduleSummary.endDate,
          )
        : null}
      {detailsMode && ['canceled', 'rejected'].includes(scheduleSummary.status)
        ? getRejectedAccordion(scheduleSummary as VacationsScheduleEntry)
        : null}
    </Box>
  );
}

function renderDetails(
  scheduleSummary: VacationsScheduleSummary | VacationsScheduleEntry,
  receiptLink: string,
  navigate: NavigateFunction,
) {
  function shouldShowPaySlipButton(
    vacationRequest: VacationsScheduleSummary | VacationsScheduleEntry,
    receiptLink: string | undefined,
  ) {
    return ['payrollApproved'].includes(vacationRequest.status) && receiptLink;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Divider
        sx={{
          my: 2,
          alignSelf: 'stretch',
          alignContent: 'center',
          display: 'flex',
        }}
      />
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'column',
          py: 1,
          gap: 2,
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            fontSize={14}
            fontWeight={450}
            display={'inline'}
          >
            {`Venda de férias`}
          </Typography>
          <Typography fontSize={16} variant="body1" fontWeight={500}>
            {`${scheduleSummary.daysSold} dias`}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            fontSize={14}
            fontWeight={450}
            display={'inline'}
          >
            {`Adiantamento da parcela do 13º`}
          </Typography>
          <Typography fontSize={16} variant="body1" fontWeight={500}>
            {scheduleSummary.thirteenthAdvance ? 'Sim' : 'Não'}
          </Typography>
        </Box>
      </Box>
      {shouldShowPaySlipButton(scheduleSummary, receiptLink) ? (
        <>
          <Divider sx={{ my: 2, alignSelf: 'stretch', height: '1px' }} />
          <Box sx={{ width: '100%' }}>
            <Button
              color={'primaryAlt'}
              onClick={receiptLink ? () => navigate(receiptLink) : undefined}
              sx={{ width: '100%', py: 1, px: 2, height: '40px' }}
            >
              Recibo do pagamento
            </Button>
          </Box>
        </>
      ) : null}
    </Box>
  );
}

export function getTag(status: string, startDate?: string, endDate?: string) {
  let tagProps: {
    color: 'default' | 'info' | 'success' | 'warning' | 'error' | 'secondary';
    textColor: string;
    text: string;
  } = {
    color: 'default',
    textColor: '',
    text: '',
  };
  switch (status) {
    case 'approved':
    case 'payrollCreated':
    case 'payrollApproved':
      if (dayjs(startDate).isBefore(dayjs(), 'day')) {
        if (dayjs(endDate).isBefore(dayjs(), 'day')) {
          tagProps = {
            color: 'secondary',
            textColor: 'text.secondary',
            text: 'Realizada',
          };
          break;
        }
        tagProps = {
          color: 'info',
          textColor: 'info.main',
          text: 'Em curso',
        };
        break;
      }
      tagProps = {
        color: 'success',
        textColor: 'success.main',
        text: 'Aprovada',
      };
      break;
    case 'rejected':
      tagProps = {
        color: 'error',
        textColor: 'error.main',
        text: 'Recusada',
      };
      break;
    case 'canceled':
      tagProps = {
        color: 'error',
        textColor: 'error.main',
        text: 'Cancelada',
      };
      break;
    case 'waitingApproval':
      tagProps = {
        color: 'warning',
        textColor: 'warning.main',
        text: 'Aguardando aprovação',
      };
      break;
    default:
      // TODO: Add anothers status
      tagProps = {
        color: 'default',
        textColor: 'text.primary',
        text: status,
      };
  }
  return (
    <Tag color={tagProps.color}>
      <Typography
        color={tagProps.textColor}
        sx={{ fontWeight: 450, fontSize: 14 }}
      >
        {`${tagProps.text}`}
      </Typography>
    </Tag>
  );
}

function getRejectedAccordion(scheduleSummary: VacationsScheduleEntry) {
  const review = (scheduleSummary as VacationsScheduleEntry).review
    ? (scheduleSummary as VacationsScheduleEntry).review
    : null;
  return (
    <Accordion
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        disableGutters: true,
        width: '100%',
        border: 'none',
        borderRadius: '12px',
        backgroundColor: theme.palette.background.error,
        '&.MuiAccordion-root': {
          '&::before': {
            display: 'none',
          },
        },
      })}
    >
      <AccordionSummary
        expandIcon={review ? <ExpandMore /> : null}
        sx={(theme) => ({
          px: 1,
          py: 0,
          width: '100%',
          alignContent: 'center',
          height: '48px',
          border: 'none',
          borderRadius: '12px',
          backgroundColor: theme.palette.background.error,
        })}
      >
        {scheduleSummary.status === 'rejected' ? (
          <Typography
            display="inline"
            sx={(theme) => ({
              color: theme.palette.error.lowEmphasis,
              fontWeight: 650,
              fontSize: '12px',
            })}
          >
            {'Recusada '}
            {review ? (
              <Typography
                display="inline"
                sx={{
                  fontWeight: 450,
                  fontSize: '12px',
                  display: 'inline',
                }}
              >{`em ${dayjs(review.date).format('DD/MM/YY')} às ${dayjs(review.date).format('HH:mm')}, por ${review.userName} `}</Typography>
            ) : (
              <Typography
                display="inline"
                sx={{
                  fontWeight: 450,
                  fontSize: '12px',
                  display: 'inline',
                }}
              >{`em ${dayjs(scheduleSummary.approvalDeadline).format('DD/MM/YY')}, automaticamente `}</Typography>
            )}
          </Typography>
        ) : (
          <Typography
            display="inline"
            sx={(theme) => ({
              color: theme.palette.error.lowEmphasis,
              fontWeight: 650,
              fontSize: '12px',
            })}
          >
            {'Cancelada '}
            <Typography
              display="inline"
              sx={{
                fontWeight: 450,
                fontSize: '12px',
                display: 'inline',
              }}
            >
              {`em ${dayjs(scheduleSummary.lastUpdatedOn).format('DD/MM/YY')} às ${dayjs(scheduleSummary.lastUpdatedOn).format('HH:mm')}, por ${scheduleSummary.workerData.name} `}
            </Typography>
          </Typography>
        )}
      </AccordionSummary>
      {review ? (
        <AccordionDetails
          sx={(theme) => ({
            px: 1,
            py: 0,
            borderRadius: '12px',
            border: 'none',
            alignContent: 'center',
            backgroundColor: theme.palette.background.error,
            pb: 1,
          })}
        >
          <Typography
            variant="body2"
            sx={{
              fontSize: '12px',
              fontWeight: '650',
              lineHeight: 2,
            }}
          >
            Motivo da recusa
          </Typography>
          <Typography variant="h3" sx={{ fontSize: '14px', fontWeight: '450' }}>
            {`${review.comment}`}
          </Typography>
        </AccordionDetails>
      ) : null}
    </Accordion>
  );
}

function shouldRenderDetailsButton(detailsMode: boolean, historyMode: boolean) {
  return !detailsMode && !historyMode;
}

function shouldRenderDetails(detailsMode: boolean, historyMode: boolean) {
  return detailsMode === true && !historyMode;
}

function shouldRenderTag(
  scheduleSummary: VacationsScheduleSummary | VacationsScheduleEntry,
  detailsMode: boolean,
  historyMode: boolean,
) {
  return (
    ((detailsMode || !historyMode) &&
      !['rejected', 'canceled'].includes(scheduleSummary.status)) ||
    (['rejected', 'canceled'].includes(scheduleSummary.status) && historyMode)
  );
}
