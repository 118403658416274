const PayrollErrorCodes: Record<string, string> = {
  'RE002/PC001': 'Estagiario pode criar apenas folha mensal ou de rescisão',
  'RE002/PC002':
    'Diretor sem FGTS pode criar apenas folha mensal ou de rescisão',
  'RE002/PC003':
    'Não é possível criar rescisão para colaborador em gozo de férias',
  'RE002/PC004':
    'Não é possível criar rescisão para colaborador com férias planejadas',
  'RE002/PC005':
    'Não é possível criar rescisão para colaborador durante suas férias',
};

export default PayrollErrorCodes;
