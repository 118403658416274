import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';

import { Counts } from '../../modules/components/Counts';

export type VacationsTotals = {
  byProp: {
    [key: string]: [any, number];
  };
};

export const VacationsCount = ({
  isLoading,
  totals,
}: {
  isLoading: boolean;
  totals: VacationsTotals;
}) => {
  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        px={0.5}
        paddingTop={3}
        pb={2}
        width="40%"
      >
        <Skeleton variant="rounded" width="100%" height="40px" />
      </Box>
    );
  }
  const labels: { [key: string]: string } = {
    hasAvailableVacations: 'Férias disponíveis',
    hasOverdueVacations: 'Próximas do vencimento',
    hasVacationsCloseToDueDate: 'Férias vencidas',
  };
  const props =
    totals?.byProp &&
    Object.entries(totals?.byProp).reduce(
      (acc: { [key: string]: any }, [key, value]) => {
        if (value?.[1]) {
          acc[labels[key]] = value?.[1];
        }
        return acc;
      },
      {},
    );

  return (
    <Box
      px={0.5}
      paddingTop={3}
      pb={2}
      width="60%"
      display="flex"
      data-testid="people-counts"
    >
      <Counts props={props} />
    </Box>
  );
};
