import React, { useEffect } from 'react';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { LegalEntitySummary, getAuthToken } from '@octopus/api';

import { download } from '../../spreadsheets/downloader';
import { loadLegalEntitiesInstance } from '../../spreadsheets/legalEntityesLoader';

type DownloadModalState = {
  open: {
    state: boolean;
    setState: (state: boolean) => void;
  };
  error: {
    state: boolean;
    setState: (state: boolean) => void;
  };
  loading: {
    state: boolean;
    setState: (state: boolean) => void;
  };
};

type DownloadModalRequirements = {
  period: boolean;
};

export type SupportedPayrollTypes =
  | 'payroll'
  | 'advance'
  | 'complementary'
  | 'termination'
  | 'vacations'
  | 'thirteenth';

type DownloadModalProps = {
  organizationId: string;
  companyId: string;
  type: SupportedPayrollTypes | 'overrideReporting';
  label: string;
  requirements: DownloadModalRequirements;
  states: DownloadModalState;
};

const loadLegalEntities = loadLegalEntitiesInstance();

export default function DownloadModal({
  organizationId,
  companyId,
  type,
  label,
  requirements,
  states,
}: DownloadModalProps) {
  const now = new Date();

  const [month, setMonth] = React.useState(now.getMonth() + 1);
  const [year, setYear] = React.useState(now.getFullYear());
  const [legalEntity, setLegalEntity] = React.useState({
    companyId: '',
    legalEntityId: '',
  });

  const [allLegalEntities, setAllLegalEntity] = React.useState(false);

  const [legalEntities, setLegalEntities] = React.useState<
    LegalEntitySummary[]
  >([]);

  useEffect(() => {
    loadLegalEntities(organizationId, companyId).then((legalEntities) => {
      setLegalEntities(legalEntities);

      const legalEntity = legalEntities[0];
      if (!legalEntity) {
        return;
      }

      setLegalEntity({
        companyId: legalEntity.companyId,
        legalEntityId: legalEntity.legalEntityId,
      });
    });
  }, [organizationId, companyId]);

  const handleAllLegalEntityChanged = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAllLegalEntity(event.target.checked);
  };

  const handleLegalEntityChange = (event: SelectChangeEvent) => {
    const legalEntity = legalEntities.find(
      (legalEntity) => legalEntity.legalEntityId === event.target.value,
    );
    if (!legalEntity) {
      return;
    }

    setLegalEntity({
      companyId: legalEntity.companyId,
      legalEntityId: event.target.value,
    });
  };

  const handleMonthChange = (event: SelectChangeEvent) => {
    setMonth(Number(event.target.value));
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    setYear(Number(event.target.value));
  };

  const handleSubmit = () => {
    states.loading.setState(true);
    states.error.setState(false);
    states.open.setState(false);

    const params = {
      ...(requirements.period
        ? {
            year: year.toString(),
            month: month.toString().padStart(2, '0'),
          }
        : {}),
      ...(!allLegalEntities && {
        companyId: legalEntity.companyId,
        legalEntityId: legalEntity.legalEntityId,
      }),
    };

    download(getAuthToken()!, organizationId, type, params)
      .then(() => {
        states.loading.setState(false);
      })
      .catch(() => {
        states.loading.setState(false);
        states.error.setState(true);
      });
  };

  const handleClose = () => {
    states.open.setState(false);
  };

  return (
    <Dialog open={states.open.state} onClose={handleClose} disableEscapeKeyDown>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        <div>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <FormControlLabel
              id="payroll-allLegalEntities-checkbox-label"
              control={
                <Checkbox
                  id="payroll-allLegalEntities-checkbox-label"
                  onChange={handleAllLegalEntityChanged}
                  defaultChecked={allLegalEntities}
                />
              }
              label={
                <Typography fontWeight="500">
                  Todas as Entidades Legais
                </Typography>
              }
            />
          </FormControl>

          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="payroll-legalEntity-select-label">
              Entidade Legal
            </InputLabel>

            <Select
              labelId="payroll-legalEntity-select-label"
              id="payroll-legalEntity-select"
              label="Entidade Legal"
              onChange={handleLegalEntityChange}
              defaultValue={legalEntity.legalEntityId}
              disabled={allLegalEntities}
              autoWidth
              sx={{ minWidth: 300 }}
            >
              {legalEntities.map((legalEntity) => (
                <MenuItem
                  key={legalEntity.legalEntityId}
                  value={legalEntity.legalEntityId}
                >
                  {legalEntity.br.nomeFantasia} - {legalEntity.br.cnpj}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {requirements.period && (
          <div>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="payroll-month-select-label">Mês</InputLabel>
              <Select
                labelId="payroll-month-select-label"
                id="payroll-month-select"
                label="Mês"
                value={month.toString()}
                onChange={handleMonthChange}
                autoWidth
              >
                <MenuItem value={1}>Janeiro</MenuItem>
                <MenuItem value={2}>Fevereiro</MenuItem>
                <MenuItem value={3}>Março</MenuItem>
                <MenuItem value={4}>Abril</MenuItem>
                <MenuItem value={5}>Maio</MenuItem>
                <MenuItem value={6}>Junho</MenuItem>
                <MenuItem value={7}>Julho</MenuItem>
                <MenuItem value={8}>Agosto</MenuItem>
                <MenuItem value={9}>Setembro</MenuItem>
                <MenuItem value={10}>Outubro</MenuItem>
                <MenuItem value={11}>Novembro</MenuItem>
                <MenuItem value={12}>Dezembro</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="payroll-year-select-label">Ano</InputLabel>
              <Select
                labelId="payroll-year-select-label"
                id="payroll-year-select"
                label="Ano"
                value={year.toString()}
                onChange={handleYearChange}
                autoWidth
              >
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
                <MenuItem value={2025}>2025</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Cancelar
        </Button>
        <Button onClick={handleSubmit}>Download</Button>
      </DialogActions>
    </Dialog>
  );
}
