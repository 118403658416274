import { EnglishErrorCodes } from './en';
import { PortugueseErrorCodes } from './pt';
import { Locale } from './types';

const ErrorCodeKeys: Record<string, string> = Object.keys(
  EnglishErrorCodes,
).reduce((acc, key) => ({ ...acc, [key]: key }), {});

const ErrorCodes = {
  pt: {
    ...PortugueseErrorCodes,
  },
  en: {
    ...EnglishErrorCodes,
  },
};

const translate = (key: string, locale?: Locale): string | undefined => {
  const errorCodeKey = ErrorCodeKeys[key];
  if (!errorCodeKey) {
    return key;
  }

  return ErrorCodes[locale ?? 'en']?.[key] || ErrorCodes.en[key];
};

export { ErrorCodeKeys, translate };
