import {
  get,
  identity,
  isArray,
  isBoolean,
  isNumber,
  isPlainObject,
  isString,
} from 'lodash';

import { ContractEntry, EventSourcingEvent } from '@octopus/api';
import { banksList } from '@octopus/contract-types';
import {
  CBOs,
  Categorias,
  CondicaoIngresso,
  ContratoRegimeParcial,
  EstadoCivil,
  Estados,
  GrauInstrucao,
  IndicativoAdmissao,
  Mapper,
  Municipios,
  Nacionalidades,
  NaturezaAtividade,
  NaturezaEstagio,
  NivelEstagio,
  Paises,
  RacaCor,
  Sexo,
  TempoResidencia,
  TipoAdmissao,
  TipoContrato,
  TipoDependente,
  TipoJornada,
  TipoLogradouro,
  TipoRegimePrevidenciario,
  UnidadeSalarioFixo,
} from '@octopus/esocial/mapper';
import {
  capitalize,
  formatBooleanBR,
  formatCBO,
  formatCEP,
  formatCNAE,
  formatCNPJ,
  formatCPF,
  formatDateBR,
  formatMoney,
  formatMonthBR,
  formatPhoneBR,
} from '@octopus/formatters';

import { IAppContext } from '../../../../../modules/types';

import { unary, whenDefined } from './functionHelpers';

export type ContractFieldChange = {
  path: string;
  oldData: unknown;
  newData: unknown;
};
/**
 * Returns the list of properties that are changed by `event` in relation to a `contract`
 * i.e. what properties from the `contract` are changed when the `event` is applied to it.
 *
 * Deeply nested properties paths are returned as '/' separated names
 *
 * @example
 *   const contract: ContractEntry = {
 *     br: {
 *       pessoa: {},
 *       // other fields ommited for brevity
 *     }
 *   };
 *   const event: EventSourcingEvent = {
 *     payload: {
 *       br: {
 *         pessoa: { nmTrab: 'John' } },
 *         dependentes: [ { nmDep: 'Mary' }, { tpDep: 1 } ]
 *       }
 *   };
 *
 *   const changes = contractFieldChanges(contract, event);
 *
 *   console.assert(
 *     changes === [
 *       {
 *         path: 'br/pessoa/nmTrab',
 *         newData: 'Johnny',
 *         oldData: 'John'
 *       },
 *       {
 *         path: 'br/dependentes/0/nmDep',
 *         newData: 'Mary',
 *         oldData: undefined
 *       },
 *       {
 *         path: 'br/dependentes/1/tpDep',
 *         newData: 1,
 *         oldData: undefined
 *       },
 *     ]
 *   );
 *
 * @link
 *  If you wish to format the changes to the UI, use the {@link formatChangedFieldLabel} and {@link formatChangedFieldData} functions
 *
 */
export function contractFieldChanges(
  contract: ContractEntry,
  event: EventSourcingEvent,
): ContractFieldChange[] {
  return dataChanges(contract, event.payload, contract, event.payload);
}
type Rec = Record<string, unknown>;
function dataChanges<T extends Rec>(
  baseSourceObject: T,
  baseEventPayload: T,
  oldData: T,
  newData: T,
  path: string | undefined = undefined,
): ContractFieldChange[] {
  let changes: ContractFieldChange[] = [];
  if (isString(newData) || isNumber(newData) || isBoolean(newData)) {
    // internal transfer event: the path in contract is different than in the event
    if (path === 'destination/legalEntityId') {
      return [{ path, newData, oldData: baseSourceObject['legalEntityId'] }];
    }

    // company transfer: the path in contract is different than in the event
    // all properties come from the event
    if (path === 'br/novaMatricula') {
      return [
        {
          path,
          newData,
          oldData: get(baseEventPayload, 'old.br.matricula'),
        },
      ];
    }
    if (path === 'newLegalEntityId') {
      return [
        {
          path,
          newData,
          oldData: undefined,
        },
      ];
    }

    // native values
    return [{ path, oldData, newData }];
  }
  if (isPlainObject(newData)) {
    for (const [k, v] of Object.entries(newData)) {
      changes = changes.concat(
        dataChanges(
          baseSourceObject,
          baseEventPayload,
          (oldData ? oldData[k] : undefined) as Rec,
          v as Rec,
          path ? `${path}/${k}` : k,
        ),
      );
    }
  }
  if (isArray(newData)) {
    changes = changes.concat(
      newData.flatMap((d, i) =>
        dataChanges(
          baseSourceObject,
          baseEventPayload,
          oldData ? oldData[i] : undefined,
          d,
          path ? `${path}/${i}` : i.toString(),
        ),
      ),
    );
  }
  return changes;
}

type FieldChangeFormatter =
  | {
      label: string | ((path: string) => string);
      data?: (value: string, appContext?: IAppContext) => string;
    }
  | { hidden: true };
// only currently editable fields that can appear in a `*contractChanged`
// event are contemplated in the map below
const FieldChangeFormatters: Record<string, FieldChangeFormatter> = {
  default: {
    label: identity,
  },
  'br/pessoa/nmTrab': {
    label: 'Nome completo',
  },
  'br/pessoa/nmSoc': {
    label: 'Nome social',
  },
  'br/pessoa/cpfTrab': {
    label: 'CPF',
    data: whenDefined(formatCPF),
  },
  'br/pessoa/sexo': {
    label: 'Sexo',
    data: byCode(Sexo),
  },
  'br/pessoa/racaCor': {
    label: 'Raça e cor',
    data: byCode(RacaCor),
  },
  'br/pessoa/estCiv': {
    label: 'Estado civil',
    data: byCode(EstadoCivil),
  },
  'br/pessoa/grauInstr': {
    label: 'Grau de instrução',
    data: byCode(GrauInstrucao),
  },
  'br/nascimento/dtNascto': {
    label: 'Data de nascimento',
    data: whenDefined(formatDateBR),
  },
  'br/nascimento/paisNascto': {
    label: 'País de nascimento',
    data: byCode(Paises),
  },
  'br/nascimento/paisNac': {
    label: 'Nacionalidade',
    data: byCode(Nacionalidades),
  },
  'br/nascimento/tmpResid': {
    label: 'Tempo de residência',
    data: byCode(TempoResidencia),
  },
  'br/nascimento/condIng': {
    label: 'Condição de ingresso no país',
    data: byCode(CondicaoIngresso),
  },
  'br/endereco/tipo': {
    label: 'Tipo de endereço',
    data: whenDefined(capitalize),
  },
  'br/endereco/cep': {
    label: 'CEP',
    data: unary(formatCEP),
  },
  'br/empresa/endereco/tpLograd': {
    label: 'Tipo logradouro',
    data: byCode(TipoLogradouro),
  },
  'br/empresa/endereco/dscLograd': {
    label: 'Logradouro',
  },
  'br/endereco/tpLograd': {
    label: 'Tipo de Logradouro',
    data: byCode(TipoLogradouro),
  },
  'br/endereco/dscLograd': {
    label: 'Logradouro',
  },
  'br/endereco/nrLograd': {
    label: 'Número',
  },
  'br/endereco/complemento': {
    label: 'Complemento',
  },
  'br/endereco/bairro': {
    label: 'Bairro',
  },
  'br/endereco/uf': {
    label: 'Unidade Federal',
    data: byCode(Estados),
  },
  'br/endereco/codMunic': {
    label: 'Cidade / Município',
    data: byCode(Municipios),
  },
  'br/contato/fonePrinc': {
    label: 'Telefone',
    data: whenDefined(formatPhoneBR),
  },
  'br/contato/emailPrinc': {
    label: 'Email pessoal',
  },
  'br/contatoDeEmergencia/nome': {
    label: 'Contato de emergência: Nome do contato',
  },
  'br/contatoDeEmergencia/telefone': {
    label: 'Contato de emergência: Telefone',
    data: whenDefined(formatPhoneBR),
  },
  'br/contatoDeEmergencia/relacao': {
    label: 'Contato de emergência: Relação',
  },
  'br/deficiencia/defFisica': {
    label: 'Possui Deficiência Física ?',
    data: formatBooleanBR,
  },
  'br/deficiencia/defAuditiva': {
    label: 'Possui Deficiência Auditiva ?',
    data: formatBooleanBR,
  },
  'br/deficiencia/defIntelectual': {
    label: 'Possui Deficiência Intelectual ?',
    data: formatBooleanBR,
  },
  'br/deficiencia/defVisual': {
    label: 'Possui Deficiência Visual ?',
    data: formatBooleanBR,
  },
  'br/deficiencia/defMental': {
    label: 'Possui Deficiência Mental ?',
    data: formatBooleanBR,
  },
  'br/deficiencia/observacao': {
    label: 'Deficiências: Descrição',
  },
  'br/deficiencia/reabReadap': {
    label: 'Deficiências: Pessoa reabilitada ou readaptada?',
    data: formatBooleanBR,
  },
  'br/deficiencia/infoCota': {
    label: 'Deficiências: Contabilizado para preenchimento de cota?',
    data: formatBooleanBR,
  },
  'br/dependentes/*/nmDep': {
    label: dependenteLabel('Nome completo'),
  },
  'br/dependentes/*/tpDep': {
    label: dependenteLabel('Parentesco'),
    data: byCode(TipoDependente),
  },
  'br/dependentes/*/descrDep': {
    label: dependenteLabel('Descrição do parentesco'),
  },
  'br/dependentes/*/dtNascto': {
    label: dependenteLabel('Data de nascimento'),
    data: whenDefined(formatDateBR),
  },
  'br/dependentes/*/cpfDep': {
    label: dependenteLabel('CPF'),
    data: formatCPF,
  },
  'br/dependentes/*/sexoDep': {
    label: dependenteLabel('Sexo'),
    data: byCode(Sexo),
  },
  'br/dependentes/*/incTrab': {
    label: dependenteLabel(
      'Possui incapacidade física ou mental para trabalho?',
    ),
    data: formatBooleanBR,
  },
  'br/dependentes/*/depIRRF': {
    label: dependenteLabel('Considerado(a) nos cálculos do Imposto de renda'),
    data: formatBooleanBR,
  },
  'br/dependentes/*/depSF': {
    label: dependenteLabel('Considerado(a) nos cálculos do Salário família'),
    data: formatBooleanBR,
  },
  workerId: {
    label: 'Matrícula na empresa',
  },
  'br/trabalho/jobTitleId': {
    label: 'Cargo',
    data: (v, appContext) =>
      appContext?.company?.jobTitles?.find(({ id }) => v === id)?.summary?.name,
  },
  'br/trabalho/CBOFuncao': {
    label: 'Função',
    data: whenDefined((d) => `${formatCBO(d)} - ${CBOs.getByCode(d)}`),
  },
  'br/trabalho/departamento': {
    label: 'Departamento',
  },
  'br/gestao/costCenterId': {
    label: 'Centro de custo',
    data: (d, appContext) =>
      appContext?.company?.costCenters?.find(({ id }) => d === id)?.name,
  },
  'br/gestao/codigoCentroCusto': {
    hidden: true,
  },
  'br/gestao/nomeCentroCusto': {
    hidden: true,
  },
  'br/vinculo/emailCorp': {
    label: 'Email corporativo',
  },
  'br/regime/dtAdm': {
    label: 'Data de admissão',
    data: whenDefined(formatDateBR),
  },
  'br/vinculo/tpRegTrab': {
    label: 'Tipo de regime trabalhista',
    data: whenDefined((d) => (d.toString() === '1' ? 'CLT' : 'Estatutário')),
  },
  'br/regime/tpAdmissao': {
    label: 'Tipo de admissão',
    data: byCode(TipoAdmissao),
  },
  'br/regime/indAdmissao': {
    label: 'Indicativo de admissão',
    data: byCode(IndicativoAdmissao),
  },
  'br/trabalho/codCateg': {
    label: 'Código da categoria',
    data: byCode(Categorias),
  },
  'br/regime/nrProcTrab': {
    label: 'Número do processo trabalhista',
  },
  'br/regime/natAtividade': {
    label: 'Natureza da atividade',
    data: byCode(NaturezaAtividade),
  },
  'br/regime/dtOpcFGTS': {
    label: 'Data de opção do FGTS',
    data: whenDefined(formatDateBR),
  },
  'br/vinculo/tpRegPrev': {
    label: 'Tipo de regime previdenciario',
    data: byCode(TipoRegimePrevidenciario),
  },
  'br/vinculo/nrProcJud': {
    label: 'Alvará judicial relativo à contratação de menor de idade',
  },
  'br/jornada/qtdHrsSem': {
    label: 'Jornada em horas',
    data: whenDefined((d) => `${d} horas`),
  },
  'br/jornada/tpJornada': {
    label: 'Tipo de jornada',
    data: byCode(TipoJornada),
  },
  'br/jornada/tmpParc': {
    label: 'Tempo parcial',
    data: byCode(ContratoRegimeParcial),
  },
  'br/jornada/horNoturno': {
    label: 'Possui horário noturno?',
    data: formatBooleanBR,
  },
  'br/jornada/descJorn': {
    label: 'Descrição da jornada semanal',
  },
  'br/duracao/tpContr': {
    label: 'Tipo de contrato',
    data: byCode(TipoContrato),
  },
  'br/duracao/dtTerm': {
    label: 'Data do término',
    data: whenDefined(formatDateBR),
  },
  'br/duracao/clauAssec': {
    label: 'Contém cláusula assecuratória',
    data: formatBooleanBR,
  },
  'br/duracao/objDet': {
    label: 'Objeto razão da contratação temporária',
  },
  'br/regime/cnpjSindCategProf': {
    label: 'CNPJ do sindicato',
    data: whenDefined((d) =>
      d === '37115367003500' ? 'Não filiado' : formatCNPJ(d),
    ),
  },
  'br/regime/dtBase': {
    label: 'Mês base da categoria',
    data: formatMonthBR,
  },
  'br/estagio/natEstagio': {
    label: 'Estágio: Natureza',
    data: byCode(NaturezaEstagio),
  },
  'br/estagio/nivEstagio': {
    label: 'Estágio: Nível',
    data: byCode(NivelEstagio),
  },
  'br/estagio/areaAtuacao': {
    label: 'Estágio: Área de atuação',
  },
  'br/estagio/dtPrevTerm': {
    label: 'Estágio: Data de termino',
    data: whenDefined(formatDateBR),
  },
  'br/estagio/nrApol': {
    label: 'Estágio: Número da apólice do seguro',
    data: whenDefined((d) => d.match(/.{5}/g)?.join(' ')),
  },
  'br/estagio/instEnsino/cnpjInstEnsino': {
    label: 'Estágio: Instituição de ensino',
    data: formatCNPJ,
  },
  'br/estagio/cnpjAgntInteg': {
    label: 'Estágio: CNPJ do agente de integração',
    data: formatCNPJ,
  },
  'br/estagio/cpfSupervisor': {
    label: 'Estágio: CPF da pessoa supervisora',
    data: formatCPF,
  },
  'br/observacao': {
    label: 'Obervações',
  },
  'br/remuneracao/vrSalFx': {
    label: 'Salário base',
    data: whenDefined(formatMoney),
  },
  'br/remuneracao/undSalFixo': {
    label: 'Unidade para base de cálculo',
    data: byCode(UnidadeSalarioFixo),
  },
  'br/remuneracao/dscSalVar': {
    label: 'Descrição da remuneração variável',
  },
  'br/pagamento/chavePix': {
    label: 'Chave Pix',
  },
  'br/pagamento/codigoBanco': {
    label: 'Banco: código',
  },
  'br/pagamento/nomeBanco': {
    label: 'Banco: nome',
    data: whenDefined((d) => banksList[d]),
  },
  'br/pagamento/agencia': {
    label: 'Banco: Agência',
  },
  'br/pagamento/conta': {
    label: 'Banco: conta',
  },
  'br/prestador/posicao/jobTitleId': {
    label: 'Cargo',
    data: (d, appContext) =>
      appContext?.company?.jobTitles?.find(({ id }) => d === id)?.summary?.name,
  },
  'br/prestador/posicao/departamento': {
    label: 'Departamento',
  },
  'br/prestador/gestao/costCenterId': {
    label: 'Centro de custo',
    data: (d, appContext) =>
      appContext?.company?.costCenters?.find(({ id }) => d === id)?.name,
  },
  'br/prestador/gestao/nomeCentroCusto': {
    hidden: true,
  },
  'br/prestador/gestao/codigoCentroCusto': {
    hidden: true,
  },
  'br/emailCorp': {
    label: 'Email corporativo',
  },
  'br/contrato/dataAssinatura': {
    label: 'Contrato assinado em',
    data: whenDefined(formatDateBR),
  },
  'br/contrato/validade': {
    label: 'Validade do contrato',
    data: whenDefined(formatDateBR),
  },
  'br/contrato/renovacao': {
    label: 'Renovação do contrato',
    data: whenDefined((d) => (d !== 'automática' ? 'Manual' : 'Automática')),
  },
  'br/contrato/inicio': {
    label: 'Data de início do contrato',
    data: whenDefined(formatDateBR),
  },
  'br/contrato/termino': {
    label: 'Data de término do contrato',
    data: whenDefined(formatDateBR),
  },
  'br/empresa/razaoSocial': {
    label: 'Contratada: Razãp social',
  },
  'br/empresa/cnpj': {
    label: 'Contratada: CNPJ',
    data: formatCNPJ,
  },
  'br/empresa/cnae': {
    label: 'Contratada: CNAE principal',
    data: formatCNAE,
  },
  'br/empresa/enquadramentoTributario': {
    label: 'Contratada: Enquadramento tributário',
    data: whenDefined((d) =>
      d === 'simples' ? 'SIMPLES Nacional' : 'Lucro Presumido',
    ),
  },
  'br/empresa/endereco/cep': {
    label: 'Empresa: CEP',
    data: unary(formatCEP),
  },
  'br/empresa/endereco/nrLograd': {
    label: 'Empresa: Número',
  },
  'br/empresa/endereco/complemento': {
    label: 'Empresa: Complemento',
  },
  'br/empresa/endereco/bairro': {
    label: 'Empresa: Bairro',
  },
  'br/empresa/endereco/uf': {
    label: 'Empresa: Unidade Federal',
  },
  'br/empresa/endereco/codMunic': {
    label: 'Empresa: Cidade / Município',
    data: byCode(Municipios),
  },
  'br/pagamento/honorarios': {
    label: 'Pagamento: Honorários',
    data: whenDefined(formatMoney),
  },
  'br/pagamento/periodicidade': {
    label: 'Pagamento: Peridiocidade',
    data: whenDefined(capitalize),
  },
  'destination/legalEntityId': {
    label: 'Transferência interna',
    data: (d, appContext) =>
      appContext?.company?.legalEntities?.find(({ id }) => id === d)?.name,
  },
  newLegalEntityId: {
    label: 'Transferência entre empresas',
    data: (d, appContext) =>
      appContext?.company?.legalEntities?.find(({ id }) => id === d)?.name,
  },
  'old/legalEntityId': {
    hidden: true,
  },
  'br/novaMatricula': {
    hidden: true,
  },
  'old/br/matricula': {
    hidden: true,
  },
  newCompanyId: {
    hidden: true,
  },
  'old/companyId': {
    hidden: true,
  },
  effectiveDate: {
    hidden: true,
  },
};

function dependenteLabel(label: string) {
  return (path: string) =>
    `Dependente ${parseInt(getIndexFromPath(path), 10) + 1}: ${label}`;
}
function getIndexFromPath(path: string) {
  return path.match(/\/\d+\//g).map((s) => s.replace(/[^\d]+/g, ''))[0];
}
function getFormatter(path: string) {
  const fieldIsDependente = path.includes('/dependentes/');
  if (!fieldIsDependente) {
    return FieldChangeFormatters[path] ?? FieldChangeFormatters.default;
  }
  const indexPath = path.replace(/\/\d+\//, '/*/');
  return FieldChangeFormatters[indexPath] ?? FieldChangeFormatters.default;
}

function byCode(mapper: Mapper) {
  return whenDefined(mapper.getByCode.bind(mapper));
}

export function isChangedFieldHidden(path: string): boolean {
  const formatter = getFormatter(path);
  return 'hidden' in formatter;
}

export function formatChangedFieldLabel(path: string): string | undefined {
  const formatter = getFormatter(path);
  if ('hidden' in formatter) return undefined;

  return typeof formatter.label === 'string'
    ? formatter.label
    : formatter.label(path);
}

export function formatChangedFieldData(
  path: string,
  data: string,
  appContext: IAppContext,
): string | undefined {
  const formatter = getFormatter(path);
  if ('hidden' in formatter) return undefined;
  return formatter.data ? formatter.data(data, appContext) : data;
}
