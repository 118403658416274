import { Box } from '@mui/material';

import { isBRCltContract } from '@octopus/contract-types';

import {
  ContatoDeEmergenciaRecord,
  EnderecoEContatoRecord,
  PagamentoRecord,
  clt,
} from '../../../modules/components/people/br';
import { RecordGroup } from '../../../modules/components/Record';

import { ContractDetailsTitle } from './ContractDetailsTitle';
import { useContractEventsHistory } from './history';
import { ContractDetailsProps } from './types';
import { useContractEdit } from './useContractEdit';

export function CltContractDetails({
  isEditable,
  refs,
  contractQuery,
  historyEnabled,
  showTitle = true,
}: ContractDetailsProps) {
  const { isLoading, data: contractInfo } = contractQuery;

  const { HistoryDrawer, ShowHistoryButton, updateHistory } =
    useContractEventsHistory({
      contractQuery,
      historyEnabled,
      projectionComponent: CltContractDetails,
    });

  const contractEdit = useContractEdit({
    contractQuery,
    isEditable,
    afterUpdate: updateHistory,
  });

  if (
    (!isLoading && (!contractInfo || !contractInfo.br)) ||
    !isBRCltContract(contractInfo) ||
    !contractEdit
  ) {
    return null;
  }

  const {
    UpdateDialog,
    updateRecordProps,
    internalTransferRecordProps,
    companyTransferRecordProps,
  } = contractEdit;

  const {
    organizationId,
    companyId,
    legalEntityId,
    status,
    workerId,
    br,
    contractId,
  } = contractInfo;
  const {
    pessoa,
    nascimento,
    endereco,
    contato,
    contatoDeEmergencia,
    deficiencia,
    dependentes,
    situacao,
    vinculo,
    trabalho,
    gestao,
    regime,
    jornada,
    duracao,
    estagio,
    sucessao,
    observacao,
    remuneracao,
    pagamento,
  } = br;

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4}>
        {showTitle ? (
          <ContractDetailsTitle
            title="Dados do colaborador(a)"
            right={ShowHistoryButton}
          />
        ) : null}
        <Box ref={refs?.personalDetailsRef} data-testid="person-details">
          <RecordGroup title="Dados Pessoais">
            <clt.InformacoesPessoaisRecord
              data={{
                pessoa,
                nascimento,
              }}
              edit={updateRecordProps}
            />
            <EnderecoEContatoRecord
              data={{
                endereco,
                contato,
              }}
              edit={updateRecordProps}
            />
            <ContatoDeEmergenciaRecord
              data={{
                contatoDeEmergencia,
              }}
              edit={updateRecordProps}
            />
            <clt.DeficienciaRecord
              data={{
                deficiencia,
              }}
              edit={updateRecordProps}
            />
            <clt.DependentesRecord
              data={{
                dependentes,
              }}
              edit={updateRecordProps}
            />
          </RecordGroup>
        </Box>
        <Box ref={refs?.contractDetailsRef} data-testid="contract-details">
          <RecordGroup title="Dados Contratuais">
            <clt.SituacaoRecord status={status} situacao={situacao} />
            <clt.EmpregadorRecord
              internalTransferRecordProps={{
                data: {
                  organizationId,
                  companyId,
                  legalEntityId,
                },
                edit: internalTransferRecordProps,
              }}
              companyTransferRecordProps={{
                data: {
                  organizationId,
                  companyId,
                  legalEntityId,
                  contractId,
                  workerId,
                  matricula: vinculo.matricula,
                },
                edit: companyTransferRecordProps,
              }}
            />
            <clt.InformacoesProfissionaisRecord
              data={{
                organizationId,
                workerId,
                vinculo,
                trabalho,
                gestao,
              }}
              edit={{
                ...updateRecordProps,
                onSave: (data, onSuccess, onError) => {
                  const {
                    update: { organizationId: _, workerId, ...changes },
                  } = data;
                  updateRecordProps.onSave(
                    { update: changes, workerId },
                    onSuccess,
                    onError,
                  );
                },
              }}
            />
            <clt.AdmissaoERegimeDeTrabalhoRecord
              data={{
                vinculo,
                trabalho,
                regime,
              }}
              edit={updateRecordProps}
            />
            <clt.JornadaDeTrabalho
              data={{
                jornada,
              }}
              edit={updateRecordProps}
            />
            <clt.DuracaoDoContrato
              data={{ duracao, regime }}
              edit={updateRecordProps}
            />
            <clt.SindicatoRecord data={{ regime }} edit={updateRecordProps} />
            <clt.EstagioRecord
              data={{
                trabalho,
                estagio,
              }}
              edit={updateRecordProps}
            />
            <clt.SucessaoDeVinculoTrabalhistaRecord sucessao={sucessao} />
            <clt.ObservacoesRecord
              data={{ observacao }}
              edit={updateRecordProps}
            />
          </RecordGroup>
        </Box>
        <Box ref={refs?.paymentDetailsRef} data-testid="payment-details">
          <RecordGroup title="Remuneração">
            <clt.RemuneracaoRecord
              data={{ remuneracao }}
              edit={updateRecordProps}
            />
            <PagamentoRecord data={{ pagamento }} edit={updateRecordProps} />
          </RecordGroup>
        </Box>
      </Box>
      {UpdateDialog}
      {HistoryDrawer}
    </>
  );
}
