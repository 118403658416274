const ContractErrorCodes: Record<string, string> = {
  CTR00001: 'br.pessoa is required when country code is BR',
  CTR00002: 'nascimento is required',
  CTR00003: 'tmpResid and condIng must be null when paisNac is 105',
  CTR00004: 'tmpResid and condIng must be filled when paisNac is not 105',
  CTR00005: 'br.endereco is required when country code is BR',
  CTR00006: 'br.deficiencia is required when country code is BR',
  CTR00007: 'invalid CPF for dependent',
  CTR00008: 'nmFuncao is required when tpRegTrab is 2',
  CTR00009: 'CBOFuncao is required when nmFuncao is set',
  CTR00010: 'CBOFuncao cannot be set if nmFuncao is empty',
  CTR00011: 'estagio is required if codCateg is 901 or 906',
  CTR00012: 'estagio cannot be set if codCateg is not 901 or 906',
  CTR00013: `natEstagio must be "N" if codCateg is 906`,
  CTR00014: 'nivEstagio is required if codCateg is 901',
  CTR00015: 'nivEstagio cannot be set to 9 if codCateg is 906',
  CTR00016: 'dtPrevTerm must be after dtAdm',
  CTR00017: 'cnpjAgntInteg must be a valid CNPJ',
  CTR00018: 'cpfSupervisor must be a valid CPF',
  CTR00019: 'cnpjInstEnsino must be a valid CNPJ',
  CTR00020: 'tpRegTrab must be 1 if codCateg is 104',
  CTR00021: 'tpRegPrev must be 1 if codCateg is 104',
  CTR00022: 'tpRegPrev cannot be 2 if codCateg is < 200',
  CTR00023: 'regime is required if tpRegTrab is 1',
  CTR00024: 'regime must not be set if tpRegTrab is not 1',
  CTR00025: 'nrProcTrab must be set if indAdmissao is 3',
  CTR00026: 'nrProcTrab must not be set if indAdmissao is not 3',
  CTR00027: 'qtdHrsSem must be set if codCateg is not 111',
  CTR00028: 'horNoturno must be set if codCateg is not 111',
  CTR00029: 'remuneracao must be set if tpRegTrab is 1',
  CTR00030: 'remuneracao must not be set if tpRegTrab is 2',
  CTR00031: 'vrSalFx must be 0 if undSalFixo is 7',
  CTR00032: 'dscSalVar must be set if undSalFixo is 6 or 7',
  CTR00033: 'duracao must be set if tpRegTrab is 1',
  CTR00034: 'duracao must not be set if tpRegTrab is 2',
  CTR00035: 'dtTerm must not be set if tpContr is 1',
  CTR00036: 'clauAssec must not be set if tpContr is 1',
  CTR00037: 'dtTerm must be set if tpContr is 2',
  CTR00038: 'objDet must be set if tpContr is 3',
  CTR00039: 'clauAssec must be set if tpContr is not 1',
  CTR00040: 'dtTerm must be equal or after dtAdm',
  CTR00041: 'dtPerExpProrTerm can only exist with dtPerExpTerm set',
  CTR00042: 'dtPerExpProrTerm must be after dtAdm dtPerExpTerm',
  CTR00043: 'sucessao must be set if tpAdmissao is 2, 3, 4 or 7',
  CTR00044: 'nrInsc must be a valid CNPJ if tpInsc is 1',
  CTR00045: 'nrInsc must be a valid CPF if tpInsc is 2',
  CTR00046: 'nrInsc must be a valid CEI if tpInsc is 6',
  CTR00047: 'dtTransf must be after dtAdm',
  CTR00048:
    'tpInsc cant be 6 unless dtTransf must be equal or before 2011-12-31',
  CTR00049:
    'tpInsc cant be 5 unless dtTransf must be equal or before 1999-06-30',
  CTR00050: 'br is required',
  CTR00051: 'contract must be clt',
  CTR00052: 'requiredField',
};

export default ContractErrorCodes;
