import React, { useContext, useState } from 'react';

import dayjs from 'dayjs';
import { isFunction } from 'lodash';

import { ChevronLeft, ExpandLess, ExpandMore } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';

import {
  EventSourcingEventWithAuthor,
  GetContractEventsResponse,
  useGetContract,
} from '@octopus/api';
import { formatDateBR } from '@octopus/formatters';
import { Tag } from '@octopus/ui/design-system';

import { AppContext } from '../../../context';
import { ContractDetailsProps } from '../types';

import { ContractEventHistoryProjection } from './ContractEventsHistoryProjection';
import { Loading } from './Loading';
import { Utils } from './utils';

type EventSelection = {
  event: EventSourcingEventWithAuthor;
  index: number;
};
type OnEventSelected = (selection: EventSelection) => void;

type ContractEventsHistoryProps = {
  organizationId: string;
  contractId: string;
  personName: string;
  onDismiss: () => void;
  projectionComponent: React.FC<ContractDetailsProps>;
  events: EventSourcingEventWithAuthor[];
};

export function ContractEventsHistory({
  events,
  organizationId,
  contractId,
  personName,
  onDismiss,
  projectionComponent,
}: ContractEventsHistoryProps) {
  const [selectedEvent, setSelectedEvent] = useState<EventSelection>({
    event: undefined,
    index: undefined,
  });
  const [projectionIsOpen, setProjectionIsOpen] = useState<boolean>(false);

  const projectEvent = (selection: EventSelection) => {
    setProjectionIsOpen(true);
    setSelectedEvent(selection);
  };

  const onPrevious = () => {
    const currentIndex = selectedEvent.index;
    setSelectedEvent({
      event: events[currentIndex - 1],
      index: currentIndex - 1,
    });
  };

  const onNext = () => {
    const currentIndex = selectedEvent.index;
    setSelectedEvent({
      event: events[currentIndex + 1],
      index: currentIndex + 1,
    });
  };

  const dismissProjectionPanel = () => {
    setProjectionIsOpen(false);
    setSelectedEvent({ event: undefined, index: undefined });
  };

  return (
    <Box display="flex" alignContent="stretch" height="100%">
      <SecondaryDrawer
        isOpen={projectionIsOpen && !!selectedEvent.event}
        width="736px"
      >
        <ContractEventHistoryProjection
          projectionComponent={projectionComponent}
          event={selectedEvent.event}
          organizationId={organizationId}
          contractId={contractId}
          controls={{
            onDismiss: dismissProjectionPanel,
            hasPrevious: selectedEvent.index > 0,
            hasNext: selectedEvent.index < events.length - 1,
            onPrevious,
            onNext,
          }}
        />
      </SecondaryDrawer>
      <ContractEventsTimelinePanel
        organizationId={organizationId}
        contractId={contractId}
        projectedEventIndex={selectedEvent.index}
        events={events}
        onDismiss={onDismiss}
        personName={personName}
        onEventSelected={projectEvent}
      />
    </Box>
  );
}

function SecondaryDrawer({
  children,
  isOpen,
  width,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  width: string;
}) {
  return (
    <Box
      height="100%"
      zIndex={1}
      sx={{
        opacity: isOpen ? 1 : 0,
        width: isOpen ? width : '0px',
        transition: 'all 0.2s',
        backgroundColor: 'background.default',
        overflowY: 'overlay',
        scrollbarWidth: 'thin',
      }}
    >
      {children}
    </Box>
  );
}

function ContractEventsTimelinePanel({
  organizationId,
  contractId,
  events,
  personName,
  onDismiss,
  onEventSelected,
  projectedEventIndex,
}: {
  organizationId: string;
  contractId: string;
  events: GetContractEventsResponse;
  personName: string;
  onDismiss: () => void;
  onEventSelected: OnEventSelected;
  projectedEventIndex?: number;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '560px',
        overflowY: 'overlay',
        scrollbarWidth: 'thin',
      }}
    >
      <ContractHistoryPanelHeader
        personName={personName}
        onDismiss={onDismiss}
      />
      <Box sx={{ pt: 1, px: 7 }}>
        {
          <ContractHistoryEventsTimeline
            organizationId={organizationId}
            contractId={contractId}
            events={events}
            onEventSelected={onEventSelected}
            projectedEventIndex={projectedEventIndex}
          />
        }
      </Box>
    </Box>
  );
}

function ContractHistoryEventsTimeline({
  organizationId,
  contractId,
  events,
  onEventSelected,
  projectedEventIndex,
}: {
  organizationId: string;
  contractId: string;
  events: GetContractEventsResponse;
  onEventSelected: OnEventSelected;
  projectedEventIndex?: number;
}) {
  const now = dayjs();
  const currentEventIndex = events.findIndex(
    (event) =>
      dayjs(event.effectiveDate).isSame(now, 'day') ||
      dayjs(event.effectiveDate).isBefore(now, 'day'),
  );

  return (
    <Box display="flex" flexDirection="row" textAlign="left">
      <Timeline
        position="right"
        sx={{
          p: 0,
          mt: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {events.map((event, i) => (
          <ContractEventItem
            organizationId={organizationId}
            contractId={contractId}
            isProjected={projectedEventIndex === i}
            key={event.sequenceId}
            eventIndex={i}
            event={event}
            isCurrent={i === currentEventIndex}
            nextEvent={events[i + 1]}
            previousEvent={events[i - 1]}
            onEventSelected={onEventSelected}
          />
        ))}
      </Timeline>
    </Box>
  );
}

function ContractEventItem({
  organizationId,
  contractId,
  event,
  eventIndex,
  nextEvent,
  previousEvent,
  isCurrent,
  onEventSelected,
  isProjected,
}: {
  organizationId: string;
  contractId: string;
  event: EventSourcingEventWithAuthor;
  eventIndex: number;
  nextEvent?: EventSourcingEventWithAuthor;
  previousEvent?: EventSourcingEventWithAuthor;
  isCurrent: boolean;
  authorName?: string;
  onEventSelected: OnEventSelected;
  isProjected: boolean;
}) {
  const { isHovered, hoveredStyle, hoverEventHandlers } =
    Utils.Hooks.useHoverBackground();

  const [diffShowing, setDiffShowing] = useState<boolean>(false);
  const toggleDiff = () => setDiffShowing((state) => !state);

  const isSelected = isHovered || isProjected || diffShowing;

  return (
    <TimelineItem
      sx={(theme) => ({
        ...hoveredStyle(theme),
        backgroundColor: isProjected
          ? theme.palette.background.primary
          : isSelected
            ? Utils.Styles.getHoveredBackgoundColor(theme)
            : undefined,
        cursor: isHovered ? 'pointer' : undefined,
        borderRadius: '12px',
        pr: 1,
        mb: 0.5,
      })}
      onClick={() => {
        if (!isHovered) return;
        onEventSelected({
          event,
          index: eventIndex,
        });
      }}
    >
      {isSelected ? (
        <IconButton
          size="small"
          sx={{
            pl: 0.5,
            pr: 0,
            mx: 0,
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent',
            },
          }}
        >
          <ChevronLeft
            color={isProjected ? 'primary' : undefined}
            sx={{ height: '12px', width: '12px', px: 0, mx: 0 }}
          />
        </IconButton>
      ) : null}

      <TimelineSeparator
        sx={{
          pr: 2,
          pl: isSelected ? 0 : 2,
        }}
      >
        <ContractEventTimelineNode
          event={event}
          previousEvent={previousEvent}
          nextEvent={nextEvent}
          isCurrent={isCurrent}
        />
      </TimelineSeparator>
      <TimelineContent
        {...hoverEventHandlers}
        sx={{
          px: 0,
          pt: 2,
          pb: 2.5,
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
          gap={2}
        >
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2">
              <strong>{Utils.Events.getEventActionName(event.type)}</strong> -{' '}
              {formatDateBR(event.effectiveDate)}
            </Typography>

            <Typography variant="caption" color="textSecondary">
              Por <strong>{event.authorName}</strong> em{' '}
              {formatDateBR(event.recordDate)}
            </Typography>
          </Box>

          <Box gap={0.5} display="flex">
            {diffShowing ? (
              <IconButton
                sx={{ borderRadius: 1, p: 0.5 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDiff();
                }}
              >
                <ExpandLess fontSize="small" />
              </IconButton>
            ) : isHovered && nextEvent ? (
              <IconButton
                sx={{ borderRadius: 1, p: 0.5 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDiff();
                }}
              >
                <ExpandMore fontSize="small" />
              </IconButton>
            ) : undefined}
            <ContractEventTitleTag
              isCurrent={isCurrent}
              event={event}
              isSelected={isSelected}
            />
          </Box>
        </Box>
        <ContractEventFieldsDiff
          organizationId={organizationId}
          contractId={contractId}
          event={event}
          nextEvent={nextEvent}
          showing={diffShowing}
        />
      </TimelineContent>
    </TimelineItem>
  );
}

type ContractEventFieldsDiffProps = {
  organizationId: string;
  contractId: string;
  showing: boolean;
  event: EventSourcingEventWithAuthor;
  nextEvent?: EventSourcingEventWithAuthor;
};
function ContractEventFieldsDiff({
  organizationId,
  contractId,
  showing,
  event,
  nextEvent,
}: ContractEventFieldsDiffProps) {
  const { appContext } = useContext(AppContext);
  const {
    isError,
    isLoading,
    data: projection,
  } = useGetContract(
    {
      pathParams: {
        organizationId,
        contractId,
      },
      queryParams: {
        sequenceId: nextEvent?.sequenceId.toString(),
        effectiveDate: nextEvent?.effectiveDate,
      },
    },
    {
      enabled: !!nextEvent && showing,
    },
  );

  if (!nextEvent || !showing) return null;
  if (isLoading) return <Loading />;
  if (isError) return null;

  const changes = Utils.Events.contractFieldChanges(projection, event).filter(
    ({ path }) => !Utils.Events.isChangedFieldHidden(path),
  );

  return (
    <>
      {changes.map(({ path, oldData, newData }, i) => (
        <Box>
          <Box
            sx={{ py: 2, px: 2 }}
            gap={0.5}
            display="flex"
            flexDirection="column"
          >
            <Typography
              variant="caption"
              color="textSecondary"
              fontWeight="bold"
            >
              {Utils.Events.formatChangedFieldLabel(path)}
            </Typography>
            {!oldData ? null : (
              <Box gap={2} display="flex" flexDirection="row">
                <LightGreyText width="40px">de</LightGreyText>
                <LightGreyText>
                  {Utils.Events.formatChangedFieldData(
                    path,
                    oldData as string,
                    appContext,
                  )}
                </LightGreyText>
              </Box>
            )}
            <Box gap={2} display="flex" flexDirection="row">
              <LightGreyText width="40px">para</LightGreyText>
              <Typography variant="caption" fontWeight="bold">
                {Utils.Events.formatChangedFieldData(
                  path,
                  newData as string,
                  appContext,
                )}
              </Typography>
            </Box>
          </Box>
          {i === changes.length - 1 ? null : (
            <Box sx={{ pl: 2, pr: 1 }}>
              <Divider component="li"></Divider>
            </Box>
          )}
        </Box>
      ))}
    </>
  );
}

function LightGreyText({
  children,
  width = undefined,
}: {
  children: string | JSX.Element | JSX.Element[];
  width?: string;
}) {
  return (
    <Typography
      variant="caption"
      sx={(theme) => ({
        width,
        color: theme.palette.strokes.heavy,
      })}
    >
      {children}
    </Typography>
  );
}

function ContractEventTitleTag({
  isCurrent,
  isSelected,
  event,
}: {
  isCurrent: boolean;
  isSelected: boolean;
  event: EventSourcingEventWithAuthor;
}) {
  const isScheduled = Utils.Events.eventIsScheduled(event);

  if (!isScheduled && !isCurrent) return null;

  const [title, color]: [string, 'info' | 'default'] = isScheduled
    ? ['Agendada', 'default']
    : ['Atual', 'info'];

  return (
    <Tag
      color={color}
      emphasis={isSelected ? 'high' : undefined}
      borderRadius="small"
      slotProps={{
        typography: { variant: 'caption' },
      }}
    >
      {title}
    </Tag>
  );
}

function ContractEventTimelineNode({
  isCurrent,
  event,
  nextEvent,
  previousEvent,
}: {
  isCurrent: boolean;
  event: EventSourcingEventWithAuthor;
  nextEvent?: EventSourcingEventWithAuthor;
  previousEvent?: EventSourcingEventWithAuthor;
}) {
  const isScheduled = Utils.Events.eventIsScheduled(event);
  const isFirst = !previousEvent;
  const isLast = !nextEvent;

  const baseTimelineDotStyle = {
    width: '2px',
    height: '2px',
    padding: 0,
    mb: 0,
    mt: 0,
  };

  let dotMuiColor = undefined;
  let dotVariant = 'outlined';
  let dotBorderColor = Utils.Styles.getPrimaryLighter;
  let separatorColor = Utils.Styles.getPrimaryLighter;

  if (isScheduled) {
    dotBorderColor = undefined;
    separatorColor = undefined;
  } else if (isCurrent) {
    dotMuiColor = 'primary';
    dotVariant = 'filled';
    dotBorderColor = undefined;
  }

  const EventTimelineDot = (
    <TimelineDot
      color={dotMuiColor as 'primary' | undefined}
      variant={dotVariant as 'filled' | 'outlined'}
      sx={(theme) => ({
        ...baseTimelineDotStyle,
        borderColor: dotBorderColor ? dotBorderColor(theme) : dotBorderColor,
      })}
    />
  );

  const EventTimelineConnectorBottom = isLast ? null : (
    <TimelineConnector
      sx={(theme) => ({
        height: isFirst ? undefined : 25,
        mb: -0.5,
        backgroundColor: separatorColor
          ? separatorColor(theme)
          : separatorColor,
      })}
    />
  );

  const topConnectorColor = isFirst
    ? undefined
    : previousEvent && Utils.Events.eventIsScheduled(previousEvent)
      ? Utils.Styles.getGrayscaleLighter
      : Utils.Styles.getPrimaryLighter;

  const EventTimelineConnectorTop = (
    <Box
      sx={(theme) => ({
        height: '24px',
        width: '2px',
        backgroundColor: isFunction(topConnectorColor)
          ? topConnectorColor(theme)
          : topConnectorColor,
      })}
    ></Box>
  );

  return (
    <>
      {EventTimelineConnectorTop}
      {EventTimelineDot}
      {EventTimelineConnectorBottom}
    </>
  );
}

function ContractHistoryPanelHeader({
  personName,
  onDismiss,
}: {
  personName: string;
  onDismiss: () => void;
}) {
  return (
    <Box
      gap={2}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'top',
        justifyContent: 'space-between',
        pt: 5,
        pb: 2,
        pr: '50px',
        pl: '50px',
      }}
    >
      <Box display="flex" flexDirection="column" textAlign="left">
        <Typography variant="body1" color="textSecondary">
          {personName}
        </Typography>
        <Typography variant="h6" gap={1}>
          Histórico de alterações
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" textAlign="right">
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            onDismiss();
          }}
          sx={{
            borderColor: 'transparent',
            minWidth: '32px',
            minHeight: '32px',
            height: '32px',
            width: '32px',
          }}
        >
          <Close sx={{ fontSize: '24px', padding: 0.5 }} />
        </Button>
      </Box>
    </Box>
  );
}
