import { useEffect, useMemo, useState } from 'react';

import { Submission } from '@conform-to/react';

import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';

import { dependentAttachmentTypes } from '@octopus/onboarding-types';

import { FormFields } from '../../../../modules/form';
import { FormFromLayout } from '../../../../modules/form/FormFromLayout';
import { Form } from '../../../../modules/form/NewForm';
import { useFormFromDefinition } from '../../../../modules/form/useFormFromDefinition';
import { UploadAdmissionAttachment } from '../../components/UploadAdmissionAttachment';
import { AdmissionFormState, FormStepDefinition } from '../form/types';

import { Dependent, getDefinition } from './formStepInputs';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  formState: AdmissionFormState;
  stepDefinition: FormStepDefinition;
  currentDependente: Dependent;
  submitLabel?: string;
  onSubmitCallback: (
    event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => void;
  organizationId: string;
  companyId: string;
  draftId: string;
  hideFileComponent?: boolean;
  persistLocal?: boolean;
  formId?: string;
};

export const NewAdmissionDependentForm = ({
  disabled,
  isLoading,
  formState,
  stepDefinition,
  currentDependente,
  submitLabel,
  onSubmitCallback,
  organizationId,
  companyId,
  draftId,
  hideFileComponent = false,
  persistLocal = false,
  formId = null,
}: Props) => {
  const onDependenteSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => {
    const submission = {
      ...formData,
      payload: {
        ...formData.payload,
        file: dependente.file,
        id: dependente.id,
      },
    };

    onSubmitCallback(event, submission);
    setDependente(null);
  };

  const { options } = stepDefinition;
  const [dependente, setDependente] = useState<Dependent>(currentDependente);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);

  const definition = getDefinition(currentDependente);
  const fullForm = useFormFromDefinition(definition, {
    id: formId ?? options?.id,
    onSubmit: onDependenteSubmit,
    persistLocal,
  });

  const form = useMemo(
    () => ({
      payloadForm: fullForm.payloadForm,
      metaForm: fullForm.metaForm,
      id: fullForm.id,
      fieldsRenderOptions: fullForm.fieldsRenderOptions,
      loadState: fullForm.loadState,
    }),
    [
      fullForm.id,
      fullForm.payloadForm,
      fullForm.metaForm,
      fullForm.fieldsRenderOptions,
      fullForm.loadState,
    ],
  );

  useEffect(() => {
    if (dependente?.id !== currentDependente?.id) {
      setDependente(currentDependente);
      form.loadState(currentDependente);
    }
  }, [form, dependente, currentDependente]);

  if (!formState || !stepDefinition) {
    return null;
  }

  return (
    <>
      <Form payloadForm={form.payloadForm} metaForm={form.metaForm}>
        <FormFromLayout
          fields={FormFields({
            fields: form.fieldsRenderOptions,
            formId: form.id,
          })}
          fieldsetInline={false}
        />
      </Form>

      {!hideFileComponent && (
        <Box sx={{ my: 2 }}>
          <UploadAdmissionAttachment
            dependentId={dependente?.id ?? '-1'}
            setFileCallback={(file) => setDependente({ ...dependente, file })}
            deleteFileCallback={(_fileId) =>
              setDependente({ ...dependente, file: null })
            }
            setIsLoading={setIsLoadingFile}
            organizationId={organizationId}
            companyId={companyId}
            draftId={draftId}
            allowedTypes={dependentAttachmentTypes}
            title="Comprovante de parentesco"
            description=""
            required={true}
            formStep="dependentes"
          />
        </Box>
      )}

      {submitLabel && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            mt: 2,
          }}
        >
          <LoadingButton
            loading={isLoading}
            disabled={isLoading || disabled || isLoadingFile}
            sx={(theme) => ({
              py: 1.25,
              marginLeft: 'auto',
              display: 'inline-flex',
              alignItems: 'center',
              width: '100%',
              [theme.breakpoints.up('md')]: {
                px: 6,
                marginLeft: 'auto',
              },
              [theme.breakpoints.down('md')]: {
                px: 2,
                display: 'block',
                width: 'calc(100svw - 32px)',
              },
            })}
            color="inherit"
            variant="outlined"
            type="submit"
            form={form.id}
          >
            {submitLabel}
          </LoadingButton>
        </Box>
      )}
    </>
  );
};
