import {
  CreateScheduleRule,
  DaysBeforeDSRRuleConfiguration,
  DaysBeforeStartRuleConfiguration,
  RulesList,
  VacationsConfigurationCreatePayload,
  createScheduleRules,
} from './configurations';

export const defaultRuleSet: RulesList = {
  art134AtLeast14Days: {
    id: createScheduleRules.art134AtLeast14Days,
    enabled: true,
  },
  art134AtLeast5Days: {
    id: createScheduleRules.art134AtLeast5Days,
    enabled: true,
  },
  contractMustBeActive: {
    id: createScheduleRules.contractMustBeActive,
    enabled: true,
  },
  daysAvailable: { id: createScheduleRules.daysAvailable, enabled: true },
  daysBeforeDSR: {
    id: createScheduleRules.daysBeforeDSR,
    enabled: true,
    days: 2,
  } as DaysBeforeDSRRuleConfiguration,
  daysBeforeStart: {
    id: createScheduleRules.daysBeforeStart,
    enabled: true,
    maximum: 180,
    minimum: 45,
  } as DaysBeforeStartRuleConfiguration,
  mayOnlySellAThirdOfVacations: {
    id: createScheduleRules.mayOnlySellAThirdOfVacations,
    enabled: true,
  },
  mayRequestThirteenthAdvance: {
    id: createScheduleRules.mayRequestThirteenthAdvance,
    enabled: true,
  },
  thirteenthAdvance: {
    id: createScheduleRules.thirteenthAdvance,
    enabled: true,
  },
  howManyFractionsMayHave: {
    id: createScheduleRules.howManyFractionsMayHave,
    maximum: 3,
    enabled: true,
  },
  thirteenthAdvanceAllowedMonths: {
    id: createScheduleRules.thirteenthAdvanceAllowedMonths,
    enabled: true,
    monthsAllowedToRequest: [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
    ],
  },
  mayNotOverlapVacationsDays: {
    id: createScheduleRules.mayNotOverlapVacationsDays,
    enabled: true,
  },
  mayOnlySellVacationsOnce: {
    id: createScheduleRules.mayOnlySellVacationsOnce,
    enabled: true,
  },
  maySellVacations: {
    id: createScheduleRules.maySellVacations,
    enabled: true,
  },
  mayScheduleVacations: {
    id: createScheduleRules.mayScheduleVacations,
    enabled: true,
  },

  mustNotHaveLeaves: {
    id: createScheduleRules.mustNotHaveLeaves,
    enabled: true,
  },
};

export const defaultVacationsCreatePayload: VacationsConfigurationCreatePayload =
  {
    paymentDateConfiguration: {
      amountOfDaysBeforeStart: 2,
      typeOfDays: 'calendar',
      adminsAllowedToChange: true,
      approvalDeadlineThresholdDays: 2,
      paymentWeekDays: [],
    },

    /**
     * CLT rules
     */
    rules: {
      owner: defaultRuleSet,
      internal: defaultRuleSet,
      ['tako:support']: Object.entries(defaultRuleSet).reduce(
        (acc, [key, value]) => {
          acc[key as CreateScheduleRule] = {
            ...value,
            enabled: false,
          };
          return acc;
        },
        {} as any,
      ),
    },
  };
