import { useState } from 'react';

import dayjs from 'dayjs';

import { Theme, lighten } from '@mui/material';

import { EventSourcingEvent, UserData } from '@octopus/api';

import {
  contractFieldChanges,
  formatChangedFieldData,
  formatChangedFieldLabel,
  isChangedFieldHidden,
} from './contractHistoryProperties';
import { useGetAllAuthoredContractEvents } from './data';

const getHoveredBackgoundColor = (theme: Theme) =>
  lighten(theme.palette.text.primary, 0.96);

function useHoverBackground(hovered = false) {
  const [isHovered, setHover] = useState<boolean>(hovered);

  const hoveredStyle = (theme: Theme) => ({
    backgroundColor: isHovered ? getHoveredBackgoundColor(theme) : undefined,
  });

  const hoverEventHandlers = {
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
  };

  return {
    hoveredStyle,
    hoverEventHandlers,
    isHovered,
  };
}

const SUPPORTED_HISTORY_EVENT_TYPES = new Set([
  'employeeOnAdmission',
  'employeeAdmitted',
  'contractChanged',
  'internalTransfer',
  'pjAdmitted',
  'pjContractChanged',
  'pjInternalTransfer',
  'companyTransfer',
]);

const EVENT_TYPES_TO_ACTION_NAME: Record<string, string> = {
  employeeOnAdmission: 'Registro em',
  employeeAdmitted: 'Registro em',
  pjAdmitted: 'Registro em',
};
const getEventActionName = (eventType: string): string =>
  EVENT_TYPES_TO_ACTION_NAME[eventType] ?? 'Alteração em';

const eventIsScheduled = (event: EventSourcingEvent) =>
  dayjs().isBefore(dayjs(event.effectiveDate), 'day');

const supportedHistoryEventsSorted = (events: EventSourcingEvent[]) =>
  events
    .filter((e) => SUPPORTED_HISTORY_EVENT_TYPES.has(e.type))
    .sort((a, b) => -a.effectiveDate.localeCompare(b.effectiveDate));

const SYSTEM_USER_NAME = 'Tako';
const SYSTEM_USER_EMAIL_DOMAIN = '@8arm.io';
const getAuthorName = (user?: UserData) =>
  !user
    ? SYSTEM_USER_NAME
    : user.userData.email.endsWith(SYSTEM_USER_EMAIL_DOMAIN)
      ? SYSTEM_USER_NAME
      : user.userData.name;

const getPrimaryLighter = (theme: Theme) => theme.palette.strokes.primary;

const getGrayscaleLighter = (theme: Theme) => theme.palette.strokes.heavy;

export const Utils = {
  Hooks: {
    useHoverBackground,
    useGetAllAuthoredContractEvents,
  },
  Events: {
    eventIsScheduled,
    getEventActionName,
    supportedHistoryEventsSorted,
    getAuthorName,
    formatChangedFieldData,
    formatChangedFieldLabel,
    contractFieldChanges,
    isChangedFieldHidden,
  },
  Styles: {
    getPrimaryLighter,
    getGrayscaleLighter,
    getHoveredBackgoundColor,
  },
};
