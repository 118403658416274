export enum VacationsTabs {
  people = 'people',
  vacationsHistory = 'vacationsHistory',
  requests = 'requests',
  calculations = 'calculations',
  // payslips = 'payslips',
}

export const vacationsTabsConfig: {
  [key in VacationsTabs]: {
    key: string;
    label: string;
    color: string;
  };
} = {
  [VacationsTabs.people]: {
    key: 'people',
    label: 'Colaboradores',
    color: 'primary',
  },
  [VacationsTabs.vacationsHistory]: {
    key: 'vacationsHistory',
    label: 'Histórico de férias',
    color: 'primary',
  },
  [VacationsTabs.requests]: {
    key: 'requests',
    label: 'Solicitações',
    color: 'primary',
  },
  [VacationsTabs.calculations]: {
    key: 'calculations',
    label: 'Cálculos',
    color: 'primary',
  },
  // [VacationsTabs.payslips]: {
  //   key: 'payslips',
  //   label: 'Pagamentos e recibos',
  //   color: 'primary',
  // },
};
