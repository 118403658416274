import React from 'react';

import {
  Box,
  Button,
  Container,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';

import { requestVacationObject } from '../utils/types';

export default function AdvanceThirteenthStepView({
  requestVacationMSV,
  request,
  setRequest,
}: {
  requestVacationMSV: any;
  request: requestVacationObject;
  setRequest: React.Dispatch<React.SetStateAction<requestVacationObject>>;
}) {
  const theme = useTheme();
  const choiceSx = {
    justifyContent: 'space-between',
    padding: '12px',
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.strokes.light,
  };
  const selectedSx = {
    ...choiceSx,
    backgroundColor: theme.palette.background.info,
    borderColor: theme.palette.strokes.info,
  };
  return (
    <Container
      test-id={'container'}
      sx={{
        flexDirection: 'column',
        display: 'inline-flex',
        justifyContent: 'center',
        alignContent: 'center',
        bgcolor: theme.palette.background.secondary,
        gap: '8px',
        height: '100%',
        pt: 3,
        px: 2,
        pb: 2,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: 0,
          pb: 28.5,
          justifyContent: 'center',
          alignContent: 'center',
          bgcolor: theme.palette.background.secondary,
          gap: '8px',
          height: '100%',
        }}
      >
        <Box>
          <Typography variant="body1" fontSize={14} fontWeight={500}>
            {
              'Você quer receber o adiantamento da parcela do 13º referente às suas férias?'
            }
          </Typography>
        </Box>

        <Button
          id="advanceThirteenth-yes"
          variant="outlined"
          sx={request.advanceThirteenth === true ? selectedSx : choiceSx}
          onClick={() => {
            setRequest({ ...request, advanceThirteenth: true });
            setTimeout(() => requestVacationMSV.goForward(), 600);
          }}
        >
          <Typography fontWeight={650} fontSize={16}>
            Sim
          </Typography>
          <Radio checked={request.advanceThirteenth === true} />
        </Button>
        <Button
          id="advanceThirteenth-no"
          variant={'outlined'}
          onClick={() => {
            setRequest({ ...request, advanceThirteenth: false });
            setTimeout(() => requestVacationMSV.goForward(), 600);
          }}
          sx={request.advanceThirteenth === false ? selectedSx : choiceSx}
        >
          <Typography fontSize={16} fontWeight={650}>
            Não
          </Typography>
          <Radio checked={request.advanceThirteenth === false} />
        </Button>
      </Box>
      <Box sx={{ alignContent: 'flex-end', flexGrow: 1 }}>
        {request.advanceThirteenth !== undefined ? (
          <Button
            color="primaryAlt"
            sx={{ width: '100%', pb: 1, px: 2.5, height: '48px' }}
            onClick={() => requestVacationMSV.goForward()}
          >
            Avançar
          </Button>
        ) : (
          <Box sx={{ height: '48px' }} />
        )}
      </Box>
    </Container>
  );
}
