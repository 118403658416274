import { MouseEvent, useState } from 'react';

import dayjs from 'dayjs';

import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Popover, Typography } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import { vacationsScheduleStatuses } from '@octopus/vacations-types';

import { ExpandableTypography } from '../../../modules/components/ExpandableTypography';
import UserAvatar from '../../../modules/components/UserAvatar';
import { getTag } from '../../../modules/components/vacation-scheduler/vacationsRequestedCard';

import { VacationsTabs } from './types';

const nameColumn: GridColDef = {
  field: 'workerData.name',
  sortable: true,
  headerName: 'Nome',
  renderHeader: (params) => {
    return <Box ml={2}>{params.field}</Box>;
  },
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.workerData.name;
  },
  renderCell: ({ value }) => {
    return !value ? (
      '-'
    ) : (
      <UserAvatar
        name={value}
        expandNameOnHover={true}
        sx={{
          '--UserAvatar-name-max-width': '12.5em',
        }}
      />
    );
  },
};

const departmentColumn: GridColDef = {
  sortable: false,
  field: 'department',
  headerName: 'Departamento',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.workerData.department;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value ?? '-'}</ExpandableTypography>;
  },
};

const vacationsBalanceColumn: GridColDef = {
  field: 'totalDaysAvailable',
  headerName: 'Dias disponíveis de férias',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row;
  },
  renderCell: ({ value: { totalDaysAvailable, hasOverdueVacations } }) => {
    return (
      <VacationsBalanceComponent
        totalDaysAvailable={totalDaysAvailable}
        hasOverdueVacations={hasOverdueVacations}
      />
    );
  },
};

const VacationsBalanceComponent = ({
  totalDaysAvailable,
  hasOverdueVacations,
}: {
  totalDaysAvailable: number;
  hasOverdueVacations: string;
}) => {
  const [WithPopover, handlePopoverOpen, handlePopoverClose] = usePopover();
  return (
    <Box display="flex" flexDirection="row" justifyContent="start">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Typography
          variant="body2"
          sx={(theme) => ({
            color: hasOverdueVacations && theme.palette.error.lowEmphasis,
          })}
        >
          {`${totalDaysAvailable} dias`}
        </Typography>
      </Box>
      {!hasOverdueVacations && (
        <>
          <Box
            ml={2}
            display="flex"
            px={1}
            py={0.5}
            borderRadius={0.5}
            sx={(theme) => ({
              color: theme.palette.error.lowEmphasis,
              bgcolor: theme.palette.background.error,
            })}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <LocalAtmIcon />

            <Typography
              ml={0.5}
              variant="body2"
              sx={(theme) => ({ color: theme.palette.error.lowEmphasis })}
            >
              {Math.min(totalDaysAvailable / 30)}
            </Typography>
          </Box>
          <WithPopover>
            {'Férias vencidas, configura pagamento de férias em dobro.'}
          </WithPopover>
        </>
      )}
    </Box>
  );
};

const concessionPeriodColumn: GridColDef = {
  field: 'currentVacationsAccrualPeriodStartAndEndDate',
  sortable: false,
  headerName: 'Período concessivo vigente',
  valueGetter: (params: GridValueGetterParams) => {
    const startDate = dayjs(
      params.row.currentVacationsAccrualPeriodStartDate,
    ).format('DD/MM/YY');
    const endDate = dayjs(
      params.row.currentVacationsAccrualPeriodEndDate,
    ).format('DD/MM/YY');
    return `${startDate} a ${endDate}`;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value ?? '-'}</ExpandableTypography>;
  },
};

const limitDateToStartVacationsColumn: GridColDef = {
  field: 'limitDateToStartVacations',
  headerName: 'Limite para início do gozo',
  valueGetter: ({ row }: GridValueGetterParams) => {
    const limitDateToStartVacations = row.limitDateToStartVacations
      ? dayjs(row.limitDateToStartVacations).format('DD/MM/YY')
      : '-';
    return {
      limitDateToStartVacations,
      ...row,
    };
  },
  renderCell: ({
    value: { limitDateToStartVacations, hasVacationsCloseToDueDate },
  }) => {
    const popoverContent =
      'Faltam menos de 60 dias para a data limite do início do gozo.';
    return (
      <AlertWithPopoverComponent
        value={limitDateToStartVacations}
        hasWarning={hasVacationsCloseToDueDate}
        popoverContent={popoverContent}
      />
    );
  },
};

const AlertWithPopoverComponent = ({
  value,
  hasWarning,
  popoverContent,
}: {
  value: string;
  hasWarning: boolean;
  popoverContent: string;
}) => {
  const [WithPopover, handlePopoverOpen, handlePopoverClose] = usePopover();
  const withStyles = hasWarning
    ? (theme: any) => ({
        color: theme.palette.warning.main,
      })
    : undefined;

  return (
    <Box
      display="flex"
      aria-haspopup="true"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <Typography sx={withStyles} variant="body2">
        {value}
      </Typography>
      {hasWarning && (
        <>
          <Box ml={1}>
            <WarningIcon sx={withStyles} />
          </Box>
          <WithPopover>{popoverContent}</WithPopover>
        </>
      )}
    </Box>
  );
};

const usePopover = (): [
  React.FC<{ children: React.ReactNode }>,
  (event: React.MouseEvent<HTMLElement>) => void,
  () => void,
] => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const Component: React.FC<{ children: React.ReactNode }> = ({ children }) => (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography
        align="center"
        variant="body2"
        sx={{
          width: '240px',
          padding: '12px',
          backgroundColor: '#25252D',
          color: '#fff',
        }}
      >
        {children}
      </Typography>
    </Popover>
  );
  return [Component, handlePopoverOpen, handlePopoverClose];
};

// Dias de gozo
const daysTakenColumn: GridColDef = {
  field: 'daysTaken',
  headerName: 'Dias de gozo',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.daysTaken;
  },
  renderCell: ({ value }) => {
    return (
      <ExpandableTypography>
        {value ? `${value} dias` : '-'}
      </ExpandableTypography>
    );
  },
};

// Período de gozo
const vacationPeriodColumn: GridColDef = {
  field: 'vacationPeriod',
  headerName: 'Período de gozo',
  valueGetter: (params: GridValueGetterParams) => {
    const startDate = dayjs(params.row.startDate).format('DD/MM/YY');
    const endDate = dayjs(params.row.endDate).format('DD/MM/YY');
    return `${startDate} a ${endDate}`;
  },
  renderCell: ({ value }) => {
    return <ExpandableTypography>{value ?? '-'}</ExpandableTypography>;
  },
};

// Status
const statusColumn: GridColDef = {
  field: 'status',
  headerName: 'Status',
  valueGetter: (params: GridValueGetterParams) => {
    return params.row.status;
  },
  renderCell: ({ value }) => {
    return getTag(value);
  },
};

// Parametros
const parametersColumn: GridColDef = {
  field: 'parameters',
  headerName: 'Parametros',
  valueGetter: (params: GridValueGetterParams) => {
    const { thirteenthAdvance, daysSold } = params.row;
    // TODO add labels
    return {
      thirteenthAdvance,
      daysSold,
    };
  },
  renderCell: ({ value: { thirteenthAdvance, daysSold } }) => {
    const abono = thirteenthAdvance ? 'Abono' : '';
    const adiantamento = daysSold ? 'Adiantamento 13º' : '';
    const parameters = [abono, adiantamento].filter(Boolean).join(', ');
    return <ExpandableTypography>{parameters}</ExpandableTypography>;
  },
};

// Aprovart até
const approvalDeadlineColumn: GridColDef = {
  field: 'approvalDeadline',
  headerName: 'Aprovar até',
  valueGetter: (params: GridValueGetterParams) => {
    const { approvalDeadline, status } = params.row;
    console.log({ status });
    return {
      approvalDeadline: approvalDeadline
        ? dayjs(approvalDeadline).format('DD/MM/YYYY')
        : '-',
      hasWarning: status === vacationsScheduleStatuses.waitingApproval,
    };
  },
  renderCell: ({ value: { approvalDeadline, hasWarning } }) => {
    const popoverContent = [
      'Caso a aprovação não seja feita antes da data limite a solicitação será',
      'recusada automaticamente',
    ].join(' ');
    return (
      <AlertWithPopoverComponent
        value={approvalDeadline}
        hasWarning={hasWarning}
        popoverContent={popoverContent}
      />
    );
  },
};

export const columnsByTab: Record<VacationsTabs, GridColDef[]> = {
  [VacationsTabs.people]: [
    nameColumn,
    departmentColumn,
    vacationsBalanceColumn,
    concessionPeriodColumn,
    limitDateToStartVacationsColumn,
  ],
  [VacationsTabs.vacationsHistory]: [
    nameColumn,
    departmentColumn,
    daysTakenColumn, // Dias de gozo - VacationsScheduleSummary.daysTaken
    vacationPeriodColumn, // Perioso de gozo - VacationsScheduleSummary.startDate VacationsScheduleSummary.endDate
    statusColumn, // Status - VacationsScheduleSummary.status
  ],
  [VacationsTabs.requests]: [
    nameColumn,
    vacationPeriodColumn, // Periodo de gozo - VacationsScheduleSummary.startDate VacationsScheduleSummary.endDate
    daysTakenColumn, // Dias de gozo - VacationsScheduleSummary.daysTaken
    parametersColumn, // Parametros - VacationsScheduleSummary.thirteenthAdvance VacationsScheduleSummary.daysSold
    approvalDeadlineColumn, // Aprovar até - VacationsScheduleSummary.approvalDeadline
    statusColumn, // Status - VacationsScheduleSummary.status
  ],
  [VacationsTabs.calculations]: [
    nameColumn,
    vacationPeriodColumn, // Periodo de gozo - VacationsScheduleSummary.startDate VacationsScheduleSummary.endDate
    daysTakenColumn, // Dias de gozo - VacationsScheduleSummary.daysTaken
    parametersColumn, // Parametros - VacationsScheduleSummary.thirteenthAdvance VacationsScheduleSummary.daysSold
    approvalDeadlineColumn, // Aprovar até - VacationsScheduleSummary.approvalDeadline
    statusColumn, // Status - VacationsScheduleSummary.status
  ],
};
