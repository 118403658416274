import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Box, Divider, Typography } from '@mui/material';

import {
  VacationsAccrualPeriodEntry,
  VacationsConfigurationEntry,
  VacationsScheduleInput,
  fetchPostVacationsSchedule,
  fetchPostValidateVacationsSchedule,
} from '@octopus/api';

import { BackButton } from '../../../../modules/components/BackButton';
import { MultiStepView } from '../../../../modules/navigation/multiStep/MultiStepView';
import { useMultiStepView } from '../../../../modules/navigation/multiStep/useMultiStepView';

import AdvanceThirteenthStepView from './steps/advanceThirteenthStep';
import DurationAndDatePickerStep from './steps/durationAndDatePickerStep';
import InitialStepView from './steps/initialStep';
import ResultStepView from './steps/result';
import ReviewAndSubmitStepView from './steps/reviewAndSubmit';
import SellVacationsStepView from './steps/sellVacationsStep';
import { LoadingScene } from './utils/RequestVacationLoadingScene';
import {
  RequestVacationFormSteps,
  inputFormSteps,
  requestVacationObject,
} from './utils/types';

type Props = {
  accrualPeriod: VacationsAccrualPeriodEntry & { nextSequence: number };
  formSteps: RequestVacationFormSteps;
  setRequestVacation: Dispatch<SetStateAction<requestVacationObject>>;
  organizationId: string | undefined;
  contractId: string | undefined;
  vacationsConfiguration: VacationsConfigurationEntry;
};

function RequestVacationContainer({
  accrualPeriod,
  formSteps,
  setRequestVacation,
  organizationId,
  contractId,
  vacationsConfiguration,
}: Props) {
  const [currStep, setCurrStep] = useState<number>(0);
  const [totalSteps, setTotalSteps] = useState<number>(0);
  const [title, setTitle] = useState<string>(undefined);
  const [errorPageShown, setErrorPageShown] = useState<boolean>(false);
  const requestVacationMSV = useMultiStepView<string, requestVacationObject>(
    formSteps,
  );
  const [isLoading, setIsLoading] = useState<{ show: boolean; title: string }>({
    show: false,
    title: '',
  });
  useEffect(() => {
    setCurrStep(requestVacationMSV.currentStep.idx);
    setTotalSteps(requestVacationMSV.steps.length - 3);
    if (
      requestVacationMSV.currentStep.data.result?.errorRuleCodes &&
      requestVacationMSV.currentStep.name === 'reviewAndSubmit'
    ) {
      setTitle('Correção de erros');
      setErrorPageShown(true);
    } else {
      switch (requestVacationMSV.currentStep.name) {
        case 'reviewAndSubmit':
          setTitle('Revisar solicitação');
          break;
        case 'result':
          setTitle('');
          break;
        default:
          setTitle('Solicitação de férias');
      }
      setErrorPageShown(false);
    }
  }, [requestVacationMSV.currentStep, requestVacationMSV.steps.length]);

  const createSubmitBody = (request: requestVacationObject) => {
    const body: VacationsScheduleInput = {
      startDate: request.date.format('YYYY-MM-DD'),
      endDate: request.date
        .add(request.duration - 1, 'day')
        .format('YYYY-MM-DD'),
      daysSold: request.sellVacation ?? 0,
      thirteenthAdvance: request.advanceThirteenth ?? false,
      sequence: accrualPeriod.nextSequence,
      accrualPeriod: {
        startDate: accrualPeriod.startDate,
        endDate: accrualPeriod.endDate,
      },
    };
    return body;
  };

  const submitVacationsScheduler = (request: requestVacationObject) => {
    const body: VacationsScheduleInput = createSubmitBody(request);
    return fetchPostVacationsSchedule({
      pathParams: {
        organizationId: organizationId,
        contractId: contractId,
      },
      body,
    });
  };

  const simulateVacationsSchedule = (request: requestVacationObject) => {
    const body: VacationsScheduleInput = createSubmitBody(request);
    return fetchPostValidateVacationsSchedule({
      pathParams: {
        organizationId: organizationId,
        contractId: contractId,
      },
      body,
    });
  };

  return isLoading.show ? (
    <LoadingScene title={isLoading.title} />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        height: '100%',
      }}
    >
      {errorPageShown || requestVacationMSV.currentStep.name === 'result' ? (
        <Box sx={{ display: 'flex', pt: 8 }} />
      ) : (
        <Box data-testid="backbutton-box" sx={{ display: 'flex', p: 0, m: 0 }}>
          <BackButton
            destination={
              requestVacationMSV.currentStep.isFirst
                ? `/vacations/${contractId}/scheduler`
                : undefined
            }
            position="static"
          />
        </Box>
      )}
      <Box
        data-testid={`vacation-request-type-header`}
        sx={{
          marginBottom: 3,
          px: '20px',
        }}
      >
        <Typography variant="h1" fontWeight={650} fontSize={24}>
          {title}
        </Typography>
        {inputFormSteps.includes(requestVacationMSV.currentStep.name) ? (
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: 14,
                fontWeight: 450,
                lineHeight: '20px',
                color: '#616161',
              }}
            >
              {`Passo ${currStep} de ${totalSteps}`}
            </Typography>
            <Divider sx={{ width: '100%', mt: 1.5 }} />
          </Box>
        ) : null}
      </Box>
      <MultiStepView multiStepView={requestVacationMSV}>
        {Object.entries(formSteps).map(([step, stepValue]) => {
          switch (step) {
            case 'initialStep':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <InitialStepView
                    accrualPeriod={accrualPeriod}
                    vacationsConfiguration={vacationsConfiguration}
                    requestVacationMSV={requestVacationMSV}
                  />
                </MultiStepView.Step>
              );
            case 'abonoPecuniario':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <SellVacationsStepView
                    requestVacationMSV={requestVacationMSV}
                    request={stepValue}
                    setRequest={setRequestVacation}
                    accrualPeriod={accrualPeriod}
                    vacationsConfiguration={vacationsConfiguration}
                  />
                </MultiStepView.Step>
              );
            case 'thirteenthAdvance':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <AdvanceThirteenthStepView
                    requestVacationMSV={requestVacationMSV}
                    request={stepValue}
                    setRequest={setRequestVacation}
                  />
                </MultiStepView.Step>
              );
            case 'dateAndDuration':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <DurationAndDatePickerStep
                    accrualPeriod={accrualPeriod}
                    requestVacationMSV={requestVacationMSV}
                    request={stepValue}
                    setRequest={setRequestVacation}
                    vacationsConfiguration={vacationsConfiguration}
                    setIsLoading={setIsLoading}
                    simulateVacationsSchedule={simulateVacationsSchedule}
                  />
                </MultiStepView.Step>
              );
            case 'reviewAndSubmit':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <ReviewAndSubmitStepView
                    accrualPeriod={accrualPeriod}
                    requestVacationMSV={requestVacationMSV}
                    request={stepValue}
                    setRequest={setRequestVacation}
                    setIsLoading={setIsLoading}
                    submitVacationsScheduler={submitVacationsScheduler}
                  />
                </MultiStepView.Step>
              );
            case 'result':
              return (
                <MultiStepView.Step name={step} key={step}>
                  <ResultStepView
                    request={stepValue}
                    requestVacationMSV={requestVacationMSV}
                  />
                </MultiStepView.Step>
              );
          }
          return null;
        })}
      </MultiStepView>
    </Box>
  );
}

export default RequestVacationContainer;
