import { useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button as ButtonMUI } from '@mui/material';

import { AdmissionDraftInputFormStepEnum } from '@octopus/api';

import { UploadAdmissionAttachment } from '../../components/UploadAdmissionAttachment';
import { AdmissionFormState, FormStepDefinition } from '../form/types';

import { documentSections } from './types';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  formState: AdmissionFormState;
  stepDefinition: FormStepDefinition;
  onSubmitCallback: (newFormState: AdmissionFormState) => void; // do we need this?
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  organizationId: string;
  companyId: string;
  draftId: string;
};

export const NewAdmissionDocuments = ({
  disabled,
  isLoading,
  onSubmitCallback,
  goToPreviousStep,
  organizationId,
  companyId,
  draftId,
}: Props) => {
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
          gap: 4,
        },
        [theme.breakpoints.down('md')]: {
          gap: 2,
          width: '100%',
          height: `calc(100svh - ${theme.spacing(38)})`,
          overflow: 'scroll',
          '::-webkit-scrollbar': { display: 'none' },
        },
      })}
    >
      {Object.entries(documentSections).map(([key, doc]) => (
        <UploadAdmissionAttachment
          setIsLoading={setIsLoadingFile}
          organizationId={organizationId}
          companyId={companyId}
          draftId={draftId}
          allowedTypes={doc.allowedTypes}
          title={doc.title}
          description={doc.description}
          required={doc.required}
          key={key}
          formStep={key as AdmissionDraftInputFormStepEnum}
          disabled={isLoadingFile}
        />
      ))}
      <Box
        sx={(theme) => ({
          width: '100%',
          [theme.breakpoints.up('md')]: { display: 'flex', paddingTop: 4 },
          [theme.breakpoints.down('md')]: {
            position: 'fixed',
            bottom: 0,
            paddingBottom: 3,
            left: 0,
            background: theme.palette.background.paper,
            borderColor: 'transparent',
            border: 'none',
            px: 4,
          },
        })}
      >
        <LoadingButton
          fullWidth
          color="primaryAlt"
          variant="contained"
          disabled={disabled || isLoadingFile}
          loading={isLoading}
          sx={(theme) => ({
            py: 1.25,
            [theme.breakpoints.up('md')]: {
              px: 6,
              marginLeft: 'auto',
              width: 'auto',
            },
            [theme.breakpoints.down('md')]: {
              px: 2,
              display: 'block',
              width: 'calc(100svw - 64px)',
            },
          })}
          onClick={() => onSubmitCallback({})}
          data-testid={`submit-dependentes`}
        >
          Avançar
        </LoadingButton>
        <ButtonMUI
          fullWidth
          color="secondary"
          variant="contained"
          disabled={disabled || isLoadingFile}
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: {
              display: 'none',
            },
            [theme.breakpoints.down('md')]: {
              py: 1.25,
              px: 2,
              mt: 1.5,
              display: 'block',
              width: 'calc(100svw - 64px)',
            },
          })}
          onClick={goToPreviousStep}
        >
          Voltar
        </ButtonMUI>
      </Box>
    </Box>
  );
};
