import React from 'react';

import { Checklist, FilePresent, UploadFile } from '@mui/icons-material';
import { Alert, Box, Container, Paper, Stack, Typography } from '@mui/material';

import { BackButton } from '../../modules/components/BackButton';
import {
  ActiveContractsDownload,
  AllContractsDownload,
  PJContractsDownload,
} from '../../modules/components/spreadsheets/ContractsDownload';
import HistoricalEventsDownload from '../../modules/components/spreadsheets/HistoricalEventsDownload';
import { InternalTransferDownload } from '../../modules/components/spreadsheets/InternalTransferDownload';
import LatestUploaded from '../../modules/components/spreadsheets/LatestUploaded';
import OverrideReportingDownload from '../../modules/components/spreadsheets/OverrideReportingDownload';
import PayrollDownload from '../../modules/components/spreadsheets/PayrollDownload';
import { ProvisionsTransfersDownload } from '../../modules/components/spreadsheets/Provisions';
import UploadSpreadsheet from '../../modules/components/spreadsheets/UploadSpreadsheet';

function Page({
  organizationId,
  companyId,
}: {
  organizationId: string;
  companyId: string;
}) {
  const [error, setError] = React.useState(false);

  return (
    <Container maxWidth="md">
      <Typography variant="h1">Planilhas</Typography>

      <Box pt={4} pb={4}>
        <Paper elevation={1} sx={{ backgroundColor: 'white' }}>
          <BackButton />

          <Box py={4} px={4}>
            <Typography variant="h2" pb={4}>
              <FilePresent fontSize="large" color="primary" />
              Download
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                Ocorreu um erro ao fazer o download da planilha, tente novamente
                ou se o erro persistir, entre em contato com nosso suporte.
              </Alert>
            )}

            <Stack spacing={2} direction="row">
              <ActiveContractsDownload
                organizationId={organizationId}
                setError={setError}
              />

              <AllContractsDownload
                organizationId={organizationId}
                setError={setError}
              />

              <PJContractsDownload
                organizationId={organizationId}
                setError={setError}
              />

              <InternalTransferDownload
                organizationId={organizationId}
                setError={setError}
              />
            </Stack>

            <Stack spacing={2} direction="row" mt={2}>
              <HistoricalEventsDownload
                organizationId={organizationId}
                setError={setError}
              />
            </Stack>

            <Stack spacing={2} direction="row" mt={2}>
              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="payroll"
              />

              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="advance"
              />

              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="complementary"
              />
            </Stack>

            <Stack spacing={2} direction="row" mt={2}>
              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="termination"
              />

              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="vacations"
              />

              <PayrollDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
                type="thirteenth"
              />

              <ProvisionsTransfersDownload
                organizationId={organizationId}
                setError={setError}
              />
            </Stack>

            <Stack spacing={2} direction="row" mt={2}>
              <OverrideReportingDownload
                organizationId={organizationId}
                companyId={companyId}
                setError={setError}
              />
            </Stack>
          </Box>
        </Paper>
      </Box>

      <Box pt={2} pb={4}>
        <Paper elevation={1} sx={{ backgroundColor: 'white' }}>
          <Box py={4} px={4}>
            <Typography variant="h2">
              <UploadFile fontSize="large" color="primary" />
              Upload
            </Typography>

            <UploadSpreadsheet organizationId={organizationId} />
          </Box>
        </Paper>
      </Box>

      <Box pt={2} pb={6}>
        <Paper elevation={1} sx={{ backgroundColor: 'white' }}>
          <Box py={4} px={4}>
            <Typography variant="h2">
              <Checklist fontSize="large" color="primary" />
              Últimos envios
            </Typography>

            <LatestUploaded organizationId={organizationId} />
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default Page;
