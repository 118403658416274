import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  cancelClick: () => void;
  confirmClick: () => void;
};

export function DocumentReviewStartDialog({
  open,
  setOpen,
  cancelClick,
  confirmClick,
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0,0,0, 0.8)',
          },
        },
      }}
    >
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: 1,
            alignItems: 'flex-start',
            textAlign: 'left',
          }}
        >
          <Typography variant="h1">Modo de revisão</Typography>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            Compare documentos enviados pelo colaborador com as informações que
            já estão na plataforma. Se encontrar alguma diferença, digite a
            informação correta no campo apropriado. Após revisar tudo, clique em{' '}
            <Typography variant="button" fontWeight="bold">
              "Salvar revisão"
            </Typography>{' '}
            para confirmar as alterações.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <>
          <Button
            variant="outlined"
            color="primaryAlt"
            sx={{ py: 1, px: 4 }}
            onClick={cancelClick}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primaryAlt"
            sx={{ py: 1, px: 4 }}
            onClick={confirmClick}
          >
            Começar revisão
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
}
