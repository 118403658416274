import React, { useMemo, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Box, Skeleton, Tab, Tabs } from '@mui/material';

import {
  SearchInput,
  fetchSearchAllScheduledVacations,
  fetchSearchVacationsAccrualPeriods,
} from '@octopus/api';
import {
  DataGrid,
  DataGridToolbar,
  FilterOptions,
  useDataGrid,
} from '@octopus/ui/data-grid';

import TabLabel from '../../modules/components/TabLabel';
import { DataFetching } from '../../modules/dataFetching';
import { useSnackbar } from '../../modules/hooks/useSnackbar';
import { prepareDataGridSearchInput } from '../../utils';

import { columnsByTab } from './utils/columns';
import { VacationsTabs, vacationsTabsConfig } from './utils/types';
import { VacationsCount, VacationsTotals } from './VacationsCount';

type VacationsTableState = {
  totals:
    | (VacationsTotals & {
        totals: number;
        totalCount: number;
        current: number;
      })
    | undefined;
  filters: {
    'worker.departments': {
      id: string;
      name: string;
      count: number;
    }[];
  };
};

function addTabFilters(searchInput: SearchInput) {
  if (!searchInput.filtering) {
    searchInput.filtering = {};
  }

  if (!searchInput.filtering.elements) {
    searchInput.filtering.elements = {};
  }

  if (!searchInput.filtering.toggles) {
    searchInput.filtering.toggles = [];
  }
}

export default function VacationsTable({
  organizationId,
}: {
  organizationId: string | undefined;
}) {
  const { showSnackbar } = useSnackbar();
  const initialState: VacationsTableState = {
    totals: undefined,
    filters: {
      'worker.departments': [],
    },
  };
  const [state, setState] = React.useState<VacationsTableState>(initialState);
  const [filteredCounters, _] = React.useState<Record<string, number>>({});

  const filters = useFilters(state.filters);
  const dataGridProps = useDataGrid({
    filters,
  });

  const [tab, setTab] = useState<keyof typeof vacationsTabsConfig>(
    VacationsTabs.people,
  );

  const { sortingProps, searchProps, paginationProps, filteringProps } =
    dataGridProps;

  const searchInput = prepareDataGridSearchInput(dataGridProps);

  addTabFilters(searchInput);

  searchInput.counting = {
    filtered: {
      byProp: {
        hasAvailableVacations: ['true'],
        hasOverdueVacations: ['true'],
        hasVacationsCloseToDueDate: ['true'],
      },
    },
    unfiltered: {
      byProp: {
        hasAvailableVacations: ['true'],
        hasOverdueVacations: ['true'],
        hasVacationsCloseToDueDate: ['true'],
      },
    },
  };

  const queryKey = [organizationId, searchInput, paginationProps, tab];
  const searchParams = {
    pathParams: {
      organizationId: organizationId ?? '',
    },
    body: searchInput,
  };

  const queryFn =
    tab === VacationsTabs.people
      ? () => fetchSearchVacationsAccrualPeriods(searchParams)
      : () => fetchSearchAllScheduledVacations(searchParams);

  const vacationsAccrualPeriodsQuery = useQuery({
    queryKey,
    queryFn,
    refetchOnWindowFocus: false,
    enabled: !!organizationId,
  });

  useMemo(() => {
    if (
      !vacationsAccrualPeriodsQuery.isError &&
      vacationsAccrualPeriodsQuery.data
    ) {
      setState((state) => ({
        ...state,
        totals: {
          current: vacationsAccrualPeriodsQuery.data.total,
          totalCount: vacationsAccrualPeriodsQuery.data.unfilteredTotal,
          ...vacationsAccrualPeriodsQuery.data.metadata?.buckets.counters,
          ...state.totals,
        },
        filters: {
          ...state.filters,
          // Perhaps there's another way to retrieve the departments
        },
      }));
    }
  }, [vacationsAccrualPeriodsQuery.isError, vacationsAccrualPeriodsQuery.data]);

  const switchTab = (tab: string) => {
    setTab(tab as keyof typeof vacationsTabsConfig);
  };

  const totals = (() => {
    if (
      vacationsAccrualPeriodsQuery?.isFetching ||
      vacationsAccrualPeriodsQuery?.isLoading
    ) {
      return undefined;
    }

    const current = state?.totals?.current ?? 0;
    const total = state?.totals?.totalCount ?? 0;

    return {
      current,
      all: total,
    };
  })();

  const getTabCount = ({
    filteredCounters,
  }: {
    filteredCounters: { [k: string]: number };
    key: string;
  }) => {
    const filteredCounter = filteredCounters['all'];
    const showCount = totals?.all !== totals?.current;
    return showCount && filteredCounter > 0 ? filteredCounter : undefined;
  };

  const onRowClick = () => {
    showSnackbar({
      isOpen: true,
      Message: 'Calma, estamos desenvolvendo',
      autoHideDuration: 3000,
    });
  };

  return (
    <>
      <VacationsCount
        isLoading={!vacationsAccrualPeriodsQuery.data}
        totals={state.totals}
      />
      <Box
        paddingBottom={1.5}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <Box alignSelf="stretch" width={'100%'}>
          <DataGridToolbar
            filters={filters}
            searchProps={searchProps}
            filteringProps={filteringProps}
            totals={totals}
            typeOfResultLabel={'resultados'}
          />
        </Box>
      </Box>
      <Box
        my={2}
        sx={(theme) => ({
          boxShadow: `0 -1px 0 ${theme.palette.strokes.light} inset`,
        })}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tabs
          value={tab}
          onChange={(_, newTab) => switchTab(newTab)}
          textColor="inherit"
          data-testid="vacations-status-tabs"
          TabIndicatorProps={{
            sx: {
              bgcolor: `${vacationsTabsConfig[tab].color}.main`,
            },
          }}
        >
          {Object.entries(vacationsTabsConfig).map(([key, tabConfig]) => (
            <Tab
              key={key}
              value={key}
              icon={
                <TabLabel
                  isSelected={key === tab}
                  count={getTabCount({
                    filteredCounters,
                    key,
                  })}
                  color={tabConfig.color}
                  label={tabConfig.label}
                />
              }
              sx={{
                ':hover': {
                  opacity: 0.75,
                },
              }}
              data-testid={`people - contractType - tab - ${key}`}
            />
          ))}
        </Tabs>
      </Box>
      <DataFetching
        fetchResult={vacationsAccrualPeriodsQuery}
        Loading={() => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          );
        }}
        Data={({ data }) => {
          return (
            <Box mt={2}>
              {data ? (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={data.total || 0}
                  getRowId={(row) => row.id}
                  rows={data.data}
                  onRowClick={() => onRowClick()}
                  columns={columnsByTab[tab]}
                />
              ) : null}
            </Box>
          );
        }}
      />
    </>
  );
}

function useFilters(_: VacationsTableState['filters']): FilterOptions {
  return [
    // TODO Adicionar filtros na api
    // makeElementListFilter({
    //   label: 'Departamento',
    //   propertyToFilter: 'department',
    //   elements: filters['worker.departments'].map(name => ({ name })),
    // })
  ];
}
