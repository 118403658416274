import React from 'react';

import { BeachAccessOutlined } from '@mui/icons-material';
import { Box, Container, Typography } from '@mui/material';

import VacationsTable from './VacationsTable';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

function NewVacationsPage({ organizationId }: VacationsProps) {
  return (
    <Box
      height="100vh"
      sx={{
        backgroundColor: 'background.paper',
        py: 9,
        px: 10,
      }}
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          data-testid="admission-header"
          gap={1}
        >
          <BeachAccessOutlined
            sx={{ height: '40px', width: '40px', marginRight: 1.5 }}
          />
          <Typography variant="h1">Férias</Typography>
        </Box>

        <Box>
          <VacationsTable organizationId={organizationId} />
        </Box>
      </Container>
    </Box>
  );
}

export default NewVacationsPage;
