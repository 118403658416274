import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Box, Skeleton, Typography } from '@mui/material';

import {
  VacationsAccrualPeriodEntry,
  VacationsConfigurationEntry,
  VacationsScheduleSummary,
  fetchGetContractVacationsSchedule,
  useGetContractAccrualPeriods,
  useGetVacationsConfigurationByContract,
} from '@octopus/api';
import { createScheduleRules } from '@octopus/vacations-types';

import NextVacationsCardSaldo, {
  nextVacationsCardSaldoProps,
} from '../../../../modules/components/vacation-scheduler/nextVacationsCardSaldo';
import VacationsRequestedCard from '../../../../modules/components/vacation-scheduler/vacationsRequestedCard';
import { DataFetching } from '../../../../modules/dataFetching';

function NextVacationsTab({
  organizationId,
}: {
  organizationId: string | undefined;
}): ReactNode {
  const { contractId } = useParams();

  const vacationsScheduledQuery = useQuery({
    queryKey: [organizationId, contractId, 'proximas', 'solicitadas'],
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    queryFn: () => {
      return fetchGetContractVacationsSchedule({
        pathParams: {
          organizationId: organizationId,
          contractId: contractId,
        },
      });
    },
    enabled: !!organizationId,
  });

  const useAccrualPeriods = useGetContractAccrualPeriods(
    {
      pathParams: {
        organizationId: organizationId,
        contractId: contractId,
      },
    },
    {
      enabled: !!organizationId,
      refetchOnMount: true,
    },
  );
  const useVacationsConfiguration = useGetVacationsConfigurationByContract(
    {
      pathParams: {
        organizationId: organizationId,
        contractId: contractId,
      },
    },
    {
      enabled: !!organizationId,
      refetchOnMount: true,
    },
  );
  const { data: accrualPeriods, isLoading } = useAccrualPeriods;
  const { data: vacationsConfiguration, isLoading: isVacationLoading } =
    useVacationsConfiguration;
  const hasOverdueVacations =
    accrualPeriods?.accrualPeriods.some((accrualPeriod) =>
      dayjs(accrualPeriod.concessionPeriod.limitDateToStartVacations).isBefore(
        dayjs(),
      ),
    ) ?? false;

  return (
    <Box
      display={'flex'}
      sx={(theme) => ({
        width: '100%',
        flexDirection: 'column',
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: { pb: 0 },
        [theme.breakpoints.up('md')]: { py: 0 },
      })}
    >
      <DataFetching
        fetchResult={vacationsScheduledQuery}
        Loading={() => {
          return (
            <Box display="flex" flexDirection="column" gap="8px" pt={1}>
              <Skeleton variant="rounded" height={300} width="100%" />
            </Box>
          );
        }}
        Data={({ data }) => {
          const response = data;
          return response.data.filter((scheduleSummary) =>
            shouldShowVacationInRequestedSection(scheduleSummary),
          ).length > 0 ? (
            <Box
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: { pb: 0 },
              })}
            >
              <Typography
                variant="body1"
                sx={{
                  color: '#616161',
                  fontSize: '14px',
                  fontWeight: 500,
                  pb: 1.5,
                }}
              >
                Solicitadas
              </Typography>
              <Box
                data-testid="box-saldo"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  pt: 0,
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {response.data.map(
                    (scheduleSummary: VacationsScheduleSummary) => {
                      return shouldShowVacationInRequestedSection(
                        scheduleSummary,
                      ) ? (
                        <VacationsRequestedCard
                          key={scheduleSummary.id}
                          scheduleSummary={scheduleSummary}
                          detailsMode={false}
                          detailsLink={`/vacations/${contractId}/requestDetails/${scheduleSummary.sequence}`}
                        />
                      ) : null;
                    },
                  )}
                </Box>
              </Box>
            </Box>
          ) : null;
        }}
      />
      <Typography
        variant="body1"
        sx={{
          color: '#616161',
          fontSize: '14px',
          fontWeight: 500,
          pb: 1.5,
          pt: 3,
        }}
      >
        Saldo
      </Typography>
      {isLoading || isVacationLoading ? (
        <Box display="flex" flexDirection="column" gap="8px" pt={1}>
          <Skeleton variant="rounded" height={300} width="100%" />
        </Box>
      ) : (
        <Box
          data-testid="box-saldo"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pt: 0,
          }}
        >
          {accrualPeriods && vacationsConfiguration ? (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {accrualPeriods.accrualPeriods
                .filter((accrualPeriod) =>
                  dayjs(accrualPeriod.startDate)
                    .subtract(1, 'day')
                    .isBefore(dayjs()),
                )
                .filter((accrualPeriod) => accrualPeriod.lost === undefined)
                .filter(
                  (accrualPeriod) =>
                    !(
                      accrualPeriod.daysAvailable === 0 &&
                      (accrualPeriod.daysTaken > 0 ||
                        accrualPeriod.daysScheduled > 0) &&
                      dayjs().isAfter(accrualPeriod.concessionPeriod.startDate)
                    ),
                )
                .map((accrualPeriod: VacationsAccrualPeriodEntry) => {
                  const props = getSaldoCardProps(
                    accrualPeriod,
                    contractId as string,
                    vacationsConfiguration,
                    hasOverdueVacations,
                  );
                  return (
                    <NextVacationsCardSaldo
                      key={props.requestLink}
                      props={props}
                    />
                  );
                })}
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
}

function isFutureVacation(scheduleSummary: VacationsScheduleSummary): boolean {
  return dayjs(scheduleSummary.endDate).isAfter(dayjs());
}

function shouldShowVacationInRequestedSection(
  scheduleSummary: VacationsScheduleSummary,
): boolean {
  return (
    isFutureVacation(scheduleSummary) &&
    !['rejected', 'canceled'].includes(scheduleSummary.status)
  );
}

function getSaldoCardProps(
  accrualPeriod: VacationsAccrualPeriodEntry,
  contractId: string,
  vacationsConfiguration: VacationsConfigurationEntry,
  hasOverdueVacations: boolean,
): nextVacationsCardSaldoProps {
  const due = getDueProp(accrualPeriod);
  const scheduleInTheFuture: boolean =
    dayjs(accrualPeriod.concessionPeriod?.startDate)
      .subtract(
        vacationsConfiguration.rules[createScheduleRules.daysBeforeStart]
          .maximum,
        'days',
      )
      .isBefore(dayjs()) && due === 'unacquired';

  const enabled: boolean =
    (dayjs(accrualPeriod.concessionPeriod?.startDate).isBefore(dayjs()) ||
      scheduleInTheFuture) &&
    vacationsConfiguration.rules[createScheduleRules.mayScheduleVacations]
      .enabled &&
    !hasOverdueVacations;

  const days =
    enabled && !scheduleInTheFuture
      ? accrualPeriod.daysAvailable
      : accrualPeriod.maximumAvailableDaysForWorker -
        accrualPeriod.daysScheduled;
  const dueDate =
    enabled && !scheduleInTheFuture
      ? accrualPeriod.concessionPeriod?.limitDateToStartVacations
      : accrualPeriod.concessionPeriod?.startDate;
  return {
    enabled: enabled,
    due: due,
    days: days,
    dueDate: dayjs(dueDate).format('DD/MM/YYYY'),
    requestLink: `/vacations/${contractId}/request?startDate=${accrualPeriod.startDate}`,
  };
}

function getDueProp(
  accrualPeriod: VacationsAccrualPeriodEntry,
): 'acquired' | 'unacquired' | 'overdue' | 'closeToDue' {
  if (
    accrualPeriod.concessionPeriod &&
    accrualPeriod.concessionPeriod.limitDateToStartVacations
  ) {
    if (
      dayjs(accrualPeriod.concessionPeriod.limitDateToStartVacations).isBefore(
        dayjs(),
      )
    ) {
      return 'overdue';
    } else if (
      dayjs(accrualPeriod.concessionPeriod.limitDateToStartVacations)
        .subtract(32, 'days')
        .isBefore(dayjs())
    ) {
      return 'closeToDue';
    }
  }
  return dayjs(accrualPeriod.concessionPeriod?.startDate).isBefore(dayjs()) &&
    (accrualPeriod.daysAvailable > 0 || accrualPeriod.daysTaken !== 0)
    ? 'acquired'
    : 'unacquired';
}

export default NextVacationsTab;
