import { useState } from 'react';

import { Close } from '@mui/icons-material';
import {
  IconButton,
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
} from '@mui/material';

export type TSnackbarProps = {
  isOpen: boolean;
  variant?: 'default' | 'error';
  StartAdornment?: React.ReactNode;
  EndAdornment?: React.ReactNode;
  Message?: React.ReactNode;
  autoHideDuration?: number;
  onClose?: MuiSnackbarProps['onClose'];
  hasCloseAction?: boolean;
};

export function Snackbar(props: TSnackbarProps) {
  const variant = props.variant || 'default';
  const hasCloseAction =
    'hasCloseAction' in props && Boolean(props.hasCloseAction);

  const [hasClickedClose, setHasClickedClose] = useState(false);
  const onClickClose = () => {
    setHasClickedClose(true);
  };

  const isOpen = !hasClickedClose && props.isOpen;

  return (
    <MuiSnackbar
      {...(props.autoHideDuration && {
        autoHideDuration: props.autoHideDuration,
      })}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{
        '.MuiSnackbarContent-root': {
          borderRadius: '8px',
          ...(variant === 'default' && {
            bgcolor: (theme) => theme.palette.text.primary,
            borderBottom: '3px solid',
            borderBottomColor: (theme) => theme.palette.strokes.info,
          }),
          ...(variant === 'error' && {
            bgcolor: (theme) => theme.palette.error.dark,
          }),
          paddingTop: 1,
          paddingBottom: 0.625,
          paddingLeft: 2,
          paddingRight: 2,
        },
        '.MuiSnackbarContent-message': {
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'pre-line',
          gap: 1.5,
        },
      }}
      open={isOpen}
      onClose={props.onClose}
      message={
        <>
          {props.StartAdornment}
          {props.Message}
          {props.EndAdornment}
          {hasCloseAction && (
            <IconButton
              onClick={onClickClose}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Close
                sx={{
                  width: '16px',
                  height: '16px',
                  padding: '2px',
                  fill: (theme) => theme.palette.primaryAlt.contrastText,
                }}
              />
            </IconButton>
          )}
        </>
      }
    />
  );
}
