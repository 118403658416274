import React from 'react';

import {
  Box,
  Button,
  Container,
  Fade,
  Radio,
  Slider,
  Typography,
  useTheme,
} from '@mui/material';

import {
  VacationsAccrualPeriodEntry,
  VacationsConfigurationEntry,
} from '@octopus/api';
import { createScheduleRules } from '@octopus/vacations-types';

import { requestVacationObject } from '../utils/types';

export default function SellVacationsStepView({
  requestVacationMSV,
  request,
  setRequest,
  accrualPeriod,
  vacationsConfiguration,
}: {
  requestVacationMSV: any;
  request: requestVacationObject;
  setRequest: React.Dispatch<React.SetStateAction<requestVacationObject>>;
  accrualPeriod: VacationsAccrualPeriodEntry;
  vacationsConfiguration: VacationsConfigurationEntry;
}) {
  const theme = useTheme();
  const choiceSx = {
    justifyContent: 'space-between',
    padding: '12px',
    paddingLeft: '24px',
    paddingRight: '24px',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.strokes.light,
  };
  const selectedSx = {
    ...choiceSx,
    backgroundColor: theme.palette.background.info,
    borderColor: theme.palette.strokes.info,
  };
  const maxDaysToSell = Math.floor(
    accrualPeriod.maximumAvailableDaysForWorker / 3,
  );
  const canSellCustomPeriod =
    !vacationsConfiguration.rules[
      createScheduleRules.mayOnlySellAThirdOfVacations
    ].enabled;
  return (
    <Container
      test-id={'container'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        bgcolor: theme.palette.background.secondary,
        gap: '8px',
        height: '100%',
        pt: 3,
        px: 2,
        pb: 2,
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 0,
          paddingBottom: 9,
          justifyContent: 'center',
          alignContent: 'center',
          bgcolor: theme.palette.background.secondary,
          gap: '8px',
          height: '100%',
        }}
      >
        <Box sx={{ pb: '8px', ml: '4px' }}>
          <Box>
            <Typography variant="body1" fontSize={14} fontWeight={500}>
              {canSellCustomPeriod
                ? 'Você quer vender dias das suas férias?'
                : 'Você quer vender um terço das suas férias?'}
            </Typography>
          </Box>
        </Box>

        <Button
          id="sellVacation-yes"
          variant="outlined"
          sx={request.sellVacation > 0 ? selectedSx : choiceSx}
          onClick={() => {
            const newValue =
              request.sellVacation === undefined || request.sellVacation === 0
                ? maxDaysToSell
                : request.sellVacation;
            setRequest({ ...request, sellVacation: newValue });
            setTimeout(
              () =>
                canSellCustomPeriod ? null : requestVacationMSV.goForward(),
              600,
            );
          }}
        >
          <Typography fontWeight={650} fontSize={16}>
            Sim
          </Typography>
          <Radio checked={request.sellVacation > 0} />
        </Button>
        <Button
          id="sellVacation-no"
          variant={'outlined'}
          onClick={() => {
            setRequest({ ...request, sellVacation: 0 });
            setTimeout(
              () =>
                canSellCustomPeriod ? null : requestVacationMSV.goForward(),
              600,
            );
          }}
          sx={request.sellVacation === 0 ? selectedSx : choiceSx}
        >
          <Typography fontSize={16} fontWeight={650}>
            Não
          </Typography>
          <Radio checked={request.sellVacation === 0} />
        </Button>
        <Fade
          in={
            request.sellVacation !== undefined &&
            request.sellVacation > 0 &&
            canSellCustomPeriod
          }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pt: 3,
              gap: 2,
              mx: '4px',
            }}
          >
            <Typography fontSize={15} fontWeight={450}>
              Arraste o circulo abaixo e escolha a quantidade de dias.
            </Typography>
            <Box
              sx={{
                px: 0.5,
              }}
            >
              <Slider
                aria-label="Quantos dias de abono pecuniario?"
                getAriaValueText={(value) => `${value}`}
                value={request.sellVacation ?? maxDaysToSell}
                onChange={(_, newValue) =>
                  setRequest({ ...request, sellVacation: newValue as number })
                }
                min={1}
                max={maxDaysToSell}
                step={1}
                marks={[
                  { value: 1, label: 1 },
                  { value: maxDaysToSell, label: maxDaysToSell },
                ]}
                valueLabelDisplay="on"
              />
            </Box>
          </Box>
        </Fade>
      </Box>
      <Box sx={{ alignContent: 'flex-end', flexGrow: 1 }}>
        {request.sellVacation !== undefined ? (
          <Button
            color="primaryAlt"
            sx={{ width: '100%', pb: 1, px: 2.5, height: '48px' }}
            onClick={() => requestVacationMSV.goForward()}
          >
            Avançar
          </Button>
        ) : (
          <Box sx={{ height: '48px' }} />
        )}
      </Box>
    </Container>
  );
}
