import React, { useEffect, useImperativeHandle } from 'react';

import {
  FormProvider,
  Submission,
  getFormProps,
  useForm,
} from '@conform-to/react';
import { getZodConstraint, parseWithZod } from '@conform-to/zod';

import { Box } from '@mui/material';

import { z } from '@octopus/i18n';
import {
  IFormDefinition,
  createMetaFormSchema,
  createValidationSchema,
} from '@octopus/libs/forms';

import { FormFields } from './FormFields';
import { FormFromLayout } from './FormFromLayout';
import { getFieldsRenderProps } from './getFieldsRenderProps';
import { TFieldSelectRenderProps } from './parseField/types';
import { MultipleFormatsSubmitButton, SubmitButton } from './SubmitButton';
import { useFormFromDefinitionState } from './useFormFromDefinitionState';
import { useMetaFormAutocompleteOptionsState } from './useMetaFormAutocompleteOptionsState';

type IFormProps = React.FormHTMLAttributes<HTMLFormElement>;

function BaseFormFromDefinition(
  {
    definition,
    submitLabel,
    onOptionsSearch,
    isLoading,
    availableFormats,
    setFormat,
    ...formArgProps
  }: Omit<IFormProps, 'onSubmit'> & {
    definition: IFormDefinition;
    submitLabel?: string;
    isLoading?: boolean;
    availableFormats?: string[];
    setFormat?: (format: string) => void;
    onOptionsSearch?: (
      fieldName: string,
      queryFieldName: string,
      queryFieldValue: string,
    ) =>
      | TFieldSelectRenderProps['select']['options']
      | undefined
      | Promise<TFieldSelectRenderProps['select']['options'] | undefined>;
    onSubmit: (
      event: React.FormEvent<HTMLFormElement>,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formData: Submission<any, string[], any>,
    ) => void;
  },
  ref: React.Ref<unknown>,
) {
  const validationSchema = createValidationSchema(definition);
  const metaFormSchema = createMetaFormSchema(definition);

  const { onSubmit: onSubmitCallbackArg, id: formIdArg } = formArgProps;

  const { metaFormState, payloadFormState } =
    useFormFromDefinitionState(formIdArg);

  const [metaForm, metaFields] = useForm({
    id: metaFormState.id,
    defaultValue: metaFormState.lastResult?.initialValue,
    constraint: getZodConstraint(metaFormSchema),
    onSubmit(event, context) {
      event.preventDefault();
      const submission = parseWithZod(context.formData, {
        schema: metaFormSchema,
      });

      const submissionResult = submission.reply();

      metaFormState.set({
        submission,
        submissionResult,
      });
    },
    onValidate({ formData }) {
      const submission = parseWithZod(formData, { schema: metaFormSchema });
      metaFormState.set({
        submission,
      });
      return submission;
    },
  });

  const defaultValue = getDefaults(validationSchema as z.AnyZodObject);

  const [payloadForm, payloadFields] = useForm({
    id: payloadFormState.id,
    defaultValue: payloadFormState.lastResult?.initialValue || defaultValue,
    constraint: getZodConstraint(validationSchema),
    onSubmit(event, context) {
      event.preventDefault();
      const submission = parseWithZod(context.formData, {
        schema: validationSchema,
      });

      const submissionResult = submission.reply();

      payloadFormState.set({
        submission,
        submissionResult,
      });
      onSubmitCallbackArg(event, submission);
    },
    onValidate({ formData }) {
      const submission = parseWithZod(formData, { schema: validationSchema });
      payloadFormState.set({
        submission,
      });
      return submission;
    },
  });

  const payloadFormErrors = payloadForm.allErrors;

  const fieldsAutocomplete = useMetaFormAutocompleteOptionsState(metaForm);

  useEffect(() => {
    if (!onOptionsSearch) return;
    const fieldAutocompleteEntries = Object.entries(fieldsAutocomplete);

    for (const [
      autocompleteFieldName,
      autocompleteState,
    ] of fieldAutocompleteEntries) {
      console.log(
        '===Field Options Search',
        autocompleteFieldName,
        autocompleteState.queryFieldValue,
      );
      const searchResult = onOptionsSearch(
        autocompleteState.targetFieldName,
        autocompleteState.queryFieldName,
        autocompleteState.queryFieldValue,
      );

      autocompleteState.resolveQuery(searchResult);
    }
  }, [fieldsAutocomplete, onOptionsSearch]);

  const fieldsRenderPropsList = getFieldsRenderProps({
    definition,
    payloadFields,
    metaFields,
    metaForm,
    payloadFormErrors,
  });

  useImperativeHandle(
    ref,
    () => {
      return {
        submission: {
          payload: payloadForm.value,
        },
      };
    },
    [payloadForm.value],
  );

  return (
    <FormProvider context={payloadForm.context}>
      <FormProvider context={metaForm.context}>
        <div id={payloadForm.errorId}>{payloadForm.errors}</div>
        <form {...getFormProps(metaForm)} />
        <form {...getFormProps(payloadForm)} />
        <FormFromLayout
          fields={FormFields({ fields: fieldsRenderPropsList })}
        />

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            paddingTop: 4,
          }}
        >
          {submitLabel && availableFormats ? (
            <MultipleFormatsSubmitButton
              formId={payloadForm.id}
              setFormat={setFormat}
            />
          ) : (
            <SubmitButton
              submitLabel={submitLabel}
              isLoading={isLoading}
              formId={payloadForm.id}
            />
          )}
        </Box>
      </FormProvider>
    </FormProvider>
  );
}

function getDefaults<Schema extends z.AnyZodObject>(schema: Schema) {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault)
        return [key, value._def.defaultValue()];
      return [key, undefined];
    }),
  );
}

export const FormFromDefinition = React.forwardRef(BaseFormFromDefinition);
